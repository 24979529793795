<template >
  <div v-if="isshowgrid">
    <va-card title="Subscription List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div slot="actions" v-if="(getRole() == 'ADMIN' || getRole() == 'SUPPORT' || getRole() == 'PROVIDER')" class="mt-1">
          <va-button color="#A87D32" icon="entypo entypo-export" @click="exportFile()">Export</va-button>
        </div>
        <div slot="actions" v-if="(getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR')">
          <va-button color="success" icon="fa fa-plus" @click="add()">Create</va-button>
          <va-button color="purple" icon="fa fa-plus" @click="initiateImport()">Bulk Create</va-button>
          <!-- <va-button color="danger" icon="fa fa-remove" :disabled="actionBtnsDisabled" @click.prevent="confirm_renew('null')">Renew</va-button> -->
        </div>
        <div slot="actions" v-if="(getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR')" class="flex md2">
          <va-select
            class="mt-3"
            placeholder="Select Filter"
            v-model="filter"
            textBy="id"
            @input="checkFilter(filter)"
            :options="filterArr"
            :error="!!filterErrors.length"
            :error-messages="filterErrors"
          />
        </div>
        <div class=" flex md2">
          <va-select
            class="mt-3"
            v-if="(getRole() == 'ADMIN') || (getRole() == 'RESELLER') || (getRole() == 'HEPI_RESELLER' || getRole() ==  'SUPPORT' || getRole() == 'PROVIDER')"
            placeholder="Select Operator"
            v-model="opfilter"
            textBy="org_name"
            searchable
            @input="getOrgFilter(opfilter)"
            :options="operFilterArr"
            :error="!!operFilterErrors.length"
            :error-messages="operFilterErrors"
          />
        </div>
      </div>
      <va-modal
        class="flex xs12"
        v-model="importModal"
        size='small'
        okText=""
        cancelText=""
        @cancel="cancelImport()"
        noEscDismiss>
        <template #header>
          <h2>Import Subscription</h2>
        </template>
        <va-button small color="danger" icon="entypo entypo-cancel" class="model_cancel " @click="cancelImport()"></va-button><br>
        <va-button v-if="!make_import" @click="makeImport()">Upload</va-button>
        <div class="row">
          <div style="flex: 1; margin-top: 1px;">
            <span class="va-form-label va-form-required-label">Allowed device for OTT</span>
            <div class="row">
              <va-radio-button
                option="mobile+tv+stb"
                v-model="allowed_device"
                label="MOBILE+TV+STB"
                @input="allowedType('mobile+tv+stb')"
              />
              <va-radio-button
                option="mobile+tv"
                v-model="allowed_device"
                label="MOBILE+TV"
                @input="allowedType('mobile+tv')"
              />
            </div>
            <span class="va-form-label va-form-required-label">Mode</span>
            <va-select
              v-if="bulkData"
              placeholder="Select Mode"
              v-model="mode"
              textBy="id"
              @input="checkCredit(mode)"
              :options="modeArr"
              :error="!!modeErrors.length"
              :error-messages="modeErrors"
            />
            <span class="va-form-label va-form-required-label">OTT</span>
            <va-select
              v-if="bulkData"
              placeholder="Select Bundle"
              v-model="bundle"
              searchable
              textBy="bundle_name"
              @input="getData(bundle)"
              :options="bundleArr"
              :error="!!bundleErrors.length"
              :error-messages="bundleErrors"
            />
            <div class="mt-1">
              <span class="va-form-label">IPTV</span>
              <multiselect
                v-if="bulkData"
                placeholder="Select Add on Bundle"
                v-model="addonbundle"
                @input="resetFlag(addonbundle)"
                multiple="true"
                label="bundle_name"
                id="example"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                track-by="id"
                :options="addonbundleArr"
              />
            </div>
            <span class="va-form-label va-form-required-label">Renewal Types</span>
            <div class="row">
              <va-radio-button
                option="no_auto_renew"
                v-model="renewal_type"
                label="No Auto Renew"
                class="black--text"
              />
              <va-radio-button
                option="renew_initial_bundle"
                v-model="renewal_type"
                label="Renew Initial Bundle"
              />
              <va-radio-button
                option="renewal_customer_bundle"
                v-model="renewal_type"
                label="Renew Customer Bundle"
              />
            </div>

            <div
              v-if="addonbundleErrors.length > 0"
              class="errors">
            </div>
            <span v-if="checkIptv && ncf_pay > 0" class="va-form-label va-form-required-label">NCF Cost</span>
            <va-input
              v-if="checkIptv && ncf_pay > 0"
              v-model="ncf_pay"
              type="text"
              :disabled="true"
            />
          </div>
          <div style="flex: 1;">
            <img class="icon" src="@/assets/bundleSub.png" style="width: 250px; height: 200px; padding-left: 20px;" />
          </div>
        </div>
        <div class="row mt-2">
          <va-file-upload
            v-if="bulkData"
            type="single"
            v-model="importData"
          />
          <a class="download_script" href="#" style="margin-left: 40px; margin-top: 8px;" v-if="bulkData" @click="downloadFormat()">Download Format</a>
        </div>
        <div v-if="bulkData" style="text-align: right;">
          <va-button type="submit" class="my-0" @click.prevent="saveImport()">Import</va-button>
          <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="cancelImport()">Cancel</va-button>
        </div>
      </va-modal>
      <va-button
        v-if="isCustPresent && ((getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR')) && checkboxFlag"
        :color="selectOtns.color"
        class="select_all" small
        :icon="selectOtns.icon"
        @click="selectOtns.click">{{selectOtns.value}}</va-button>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        :totalPages="totalPages"
        @page-selected="getAllSubscription"
        api-mode
        class="va-table"
      >
        <template slot="checkbox" v-if="checkboxFlag"  slot-scope="props">
          <va-checkbox
            :value="props.rowData.checkbox_value"
            @input="select(props.rowData)"
          />
        </template>
        <template slot="expires_on" slot-scope="props">
          <p >{{props.rowData.expires_on.split('T')[0]}}</p>
        </template>
        <template slot="activated_on" slot-scope="props">
          <p >{{props.rowData.activated_on.split('T')[0]}}</p>
        </template>
        <template slot="addon_grid" slot-scope="props">
          <img src="@/assets/GridBundle.png" title="Click to view!" v-if="props.rowData.addon_grid.length > 0" @click="addon(props.rowData)" style="width: 20px; height: 20px; margin-top: 2px; cursor: pointer;" />
        </template>
        <template slot="is_active" slot-scope="props">
          <img src="@/assets/green_button_img.png" title="Click to deactivate!" v-if="(props.rowData.timestamp == null ||(new Date().getTime() > new Date(props.rowData.timestamp).getTime())) && props.rowData.is_active && (new Date(props.rowData.expires_on).getTime()>=new Date().getTime())" @click="statusChange(props.rowData,'deactivate')" style="width: 20px; height: 20px; margin-top: 2px; cursor: pointer;" />
          <img src="@/assets/grey_button_img.png" title="Click to activate!" v-if="(props.rowData.timestamp == null ||(new Date().getTime() > new Date(props.rowData.timestamp).getTime())) && !props.rowData.is_active && (new Date(props.rowData.expires_on).getTime()>=new Date().getTime())" @click="statusChange(props.rowData,'activate')" style="width: 20px; height: 20px; margin-top: 2px; cursor: pointer;" />
        </template>

        <template slot="serialChange" slot-scope="props">
          <va-button style="margin-left: -1px;" flat small color="#00b33c" v-if="((getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR') || (getRole() == 'SUPPORT')) && props.rowData.serial_no && (props.rowData.status !== 'Deactive') " @click="swapChange(props.rowData)">Swap</va-button>
        </template>
        <!-- <template slot="pre-renewal" slot-scope="props">
        </template> -->
        <template slot="actions" slot-scope="props">
          <va-button flat small color="#FCA103" v-if="((getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR')) && props.rowData.logoutFlag" @click="remove(props.rowData,'logout')" class="ma-0">Logout</va-button>
          <va-button flat small color="#00b33c" v-if="((getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR')) && props.rowData.serial_no && (props.rowData.status !== 'Deactive') && isWithin10DaysBeforeExpiry(props.rowData.expires_on) " @click="confirm_renew(props.rowData,'pre_renewal')" class="ma-0">Pre renew</va-button>
          <va-button flat small color="#a632a8" v-if="((getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR')) && props.rowData.status=='Deactive' && (new Date(props.rowData.expires_on).getTime() < new Date().getTime())" @click.prevent="confirm_renew(props.rowData,'manual_renewal')" class="ma-0">Renew</va-button>
          <va-button flat small color="gray" icon="fa fa-pencil" v-if="(((getRole() == 'OPERATOR') || (getRole() == 'HEPI_OPERATOR') || (getRole() == 'ADMIN') || (getRole() == 'SUPPORT')) && (props.rowData.timestamp == null || (new Date().getTime() > new Date(props.rowData.timestamp).getTime())) && !((props.rowData.status =='Deactive') && (new Date().getTime() < new Date(props.rowData.expires_on))))" @click="edit(props.rowData)" class="ma-0"></va-button>
          <va-button flat small color="red" icon="fa fa-remove" v-if="getRole() == 'ADMIN' || getRole() == 'SUPPORT' " @click="remove(props.rowData,'delete')" class="ma-0"></va-button>
          <va-button flat small color="gray" icon="fa fa-exchange" class="ma-0" title ="Click to migrate" @click="confirmMigration(props.rowData)"></va-button>
        </template>
      </va-data-table>
      <va-modal
        v-model="migrate"
        size="large"
        okText="Confirm"
        cancelText="Cancel"
        @ok="migration(this.migrateData)"
        @cancel="cancel()"
      >
        <template #header>
          <b class="va-modal__title">{{commonmigrate}}</b>
        </template>
        <slot>
          <div class="mt-4 ml-2">{{ messages}}</div>
        </slot>
      </va-modal>
      <va-modal
        v-model="swapChangeModal"
        size="large"
        okText="Confirm"
        cancelText="Cancel"
        @ok="swapSerial()"
        @cancel="cancel()"
        class="flex md12"
      >
        <va-card title="Swap Serial Number" class="flex md12">
          <div>
            <span class="va-form-label">Existing Serial Number</span>
            <va-input
              v-model="existing_serial_no"
              type="text"
              :disabled="true"
            />
          </div>
          <div>
            <span class="va-form-label va-form-required-label">Serial Number</span>
            <va-select
              placeholder="Select STB"
              v-model="serial_no"
              searchable
              textBy="serialAndMac"
              :options="stbArr"
              :error="!!serialNoErrors.length"
              :error-messages="serialNoErrors"
            />
          </div>
        </va-card>
      </va-modal>
      <va-modal
        v-model="showRemoveModal"
        size='small'
        okText="Confirm"
        cancelText="Cancel"
        @ok="deleteSubscription(entity,flag)"
        @cancel="cancel()">
        <template #header>
          <b class="va-modal__title">{{commonRemove}}</b>
        </template>
        <slot>
          <div class="mt-4 ml-2">{{ message }}</div>
        </slot>
      </va-modal>
      <va-modal
        v-model="showStatusChange"
        size='small'
        okText="Confirm"
        cancelText="Cancel"
        @ok="statusChangeSubscription(val,flag)"
        @cancel="cancel()">
        <template #header>
          <b class="va-modal__title">{{statusRemove}}</b>
        </template>
        <slot>
          <div class="mt-4 ml-2">{{ statusMessage }}</div>
        </slot>
      </va-modal>
      <va-modal
        v-model="isAddon"
        size="small"
        noEscDismiss
        noOutsideDismiss
        @ok="list()"
        cancelText=""
        class="flex md12"
      >
        <template #header><h2>IPTV Bundle</h2></template>
        <va-button flat small color="danger" icon="entypo entypo-cancel" class="model_cancel" @click="list()"></va-button><br>
        <div class="ml-4">
          <span class="pre-formatted">{{addonData}}</span>
        </div>
      </va-modal>
      <va-modal
        v-model="renewConfirm"
        title="Bundle Confirmation"
        size="small"
        message="Are you want to renew the addon bundles ?"
        okText="Yes"
        cancelText="No"
        @ok="proceedmanualRenewal('YES')"
        @cancel="proceedmanualRenewal('NO')"
        no-outside-dismiss
      />
      <va-modal
        v-model="pre_renew"
        title="Renew Confirmation"
        size="small"
        message="Are you sure to Proceed?"
        okText="Yes"
        cancelText="No"
        @ok="proceedPreRenew('YES')"
        @cancel="list()"
        no-outside-dismiss
      />
    </va-card>
  </div>

  <div v-else-if='isshowForm' class="demo">
    <div class = "container1" :class="{ 'full-width': isFullWidth }">
      <div class="page1">
        <va-card :title="title" style="max-height: 100%; display: flex; flex-direction: column;">
          <template slot="actions">
            <va-button color="gray" @click="list()">List</va-button>
          </template>
          <div>
            <form>
              <span class="va-form-label va-form-required-label">Name</span>
              <va-input
                v-model="name"
                type="text"
                pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
                placeholder="Enter Subscriber Name"
                :error="!!nameErrors.length"
                :error-messages="nameErrors"
              />
              <span class="va-form-label va-form-required-label">Mobile Number</span>
              <va-input
                v-model="mobile"
                type="text"
                maxlength=10
                oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                pattern="[6-9]{1}[0-9]{9}"
                placeholder="Enter Mobile Number"
                :error="!!mobileErrors.length"
                :error-messages="mobileErrors"
              />
              <span class="va-form-label va-form-required-label">Allowed device for OTT</span>
              <div class="row ml-2" v-if="isCreate">
                <va-radio-button
                  option="mobile+tv+stb"
                  v-model="allowed_device"
                  label="MOBILE+TV+STB"
                  @input="allowedType('mobile+tv+stb')"
                />
                <va-radio-button
                  class="ml-1"
                  option="mobile+tv"
                  v-model="allowed_device"
                  label="MOBILE+TV"
                  @input="allowedType('mobile+tv')"

                />
                <va-radio-button
                  class="ml-1"
                  option="stb"
                  v-model="allowed_device"
                  label="STB"
                  @input="allowedType('stb')"
                />
              </div>
              <div class="row ml-2" v-if="isUpdate">
                <va-radio-button
                  option="mobile+tv+stb"
                  v-model="allowed_device"
                  label="MOBILE+TV+STB"
                  :disabled="true"
                />
                <va-radio-button
                  class="ml-1"
                  option="mobile+tv"
                  v-model="allowed_device"
                  label="MOBILE+TV"
                  :disabled="true"
                />
                <va-radio-button
                  class="ml-1"
                  option="stb"
                  v-model="allowed_device"
                  label="STB"
                  :disabled="true"
                />
              </div>
              <span class="va-form-label va-form-required-label"> Select Mode</span>
              <va-select
                placeholder="Select Mode"
                v-model="mode"
                textBy="id"
                @input="checkCredit(mode)"
                :options="modeArr"
                :error="!!modeErrors.length"
                :error-messages="modeErrors"
              />
              <span class="va-form-label va-form-required-label" v-if="isUpdate">OTT</span>
              <va-select
                v-if="isUpdate"
                placeholder="Select Bundle"
                v-model="bundle"
                searchable
                textBy="bundle_name"
                @input="getData(bundle)"
                :options="bundleArr"
                :error="!!bundleErrors.length"
                :error-messages="bundleErrors"
              />
              <!-- {{isCreate}}-- {{isUpdate}}-- {{isshowSecond}}</br> -->
              <span class="va-form-label va-form-required-label" v-if="isCreate">Current Bundle</span>
              <va-select
                v-if="isCreate"
                placeholder="Select Bundle"
                v-model="bundle"
                searchable
                textBy="bundle_name"
                @input="getData(bundle)"
                :options="bundleArr"
                :error="!!bundleErrors.length"
                :error-messages="bundleErrors"
              />
              <span class="va-form-label" v-if="isUpgrade && isCreate">Upgrade Bundles</span>
              <va-select
                v-if="isUpgrade && isCreate"
                placeholder="Select Bundle"
                v-model="upgradebundle"
                searchable
                @input="getupgrade(upgradebundle)"
                textBy="bundle_name"
                :options="opGlobalArr"
                :error="!!bundleErrors.length"
                :error-messages="bundleErrors"
              />
              <div class="mt-2">
                <span class="va-form-label" v-if="(isCreate && (!pre_subscription || !check_pre_bundle)) && isiptv">IPTV</span>
                <multiselect
                  v-if="(isCreate && (!pre_subscription || !check_pre_bundle)) && isiptv"
                  placeholder="Select Add on Bundle"
                  v-model="addonbundle"
                  @input="resetFlag(addonbundle)"
                  multiple="true"
                  label="bundle_name"
                  id="example"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :hide-selected="false"
                  :taggable="false"
                  track-by="id"
                  :options="addonbundleArr"
                />
                <!-- <span class="va-form-label" v-if=" isUpdate" >IPTV</span>
                        <multiselect
                          v-if="isUpdate"
                          placeholder="Select Add on Bundle"
                          v-model="addonbundle"
                          @input="getAddon(addonbundle)"
                          multiple="true"
                          label="bundle_name"
                          id="example"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :hide-selected="false"
                          :taggable="false"
                          track-by="id"
                          :options="addonbundleArr"
                        /> -->
              </div>
              <va-modal
                class="flex xs12 md12 len"
                v-model="isShowIptv"
                size="large"
                noEscDismiss
                noOutsideDismiss
                okText="Update"
                cancelText="Cancel"
                @ok="finish('direct')"
                @cancel="cancelPTVSpace()"
              >
                <span style="font-size: 21px;"><b>IPTV BUNDLES</b></span>
                <slot style="margin: 50px;">
                  <div class="va-table">
                    <table style="width: 100%;" class="va-table-responsive">
                      <thead>
                        <tr>
                          <th class="content-th" width="40%">Bundle</th>
                          <th class="content-th" width="20%">Start Date</th>
                          <th class="content-th" width="20%">End Date</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(field,i) in addonbundlefields" :key="field.id">
                          <td>
                            <va-input
                              v-if="field.getFlag"
                              v-model="field.bundle_name"
                              type="text"
                              :disabled="true"
                            />
                            <va-select
                              v-if="!field.getFlag"
                              placeholder="Select Add on Bundle"
                              v-model="field.bundle_name"
                              searchable
                              textBy="bundle_name"
                              :options="addonbundleArr"
                              @input="getAddon(field)"
                            />
                          </td>
                          <td>
                            <va-input
                              class="mt-1"
                              v-model="field.start_date"
                              type="text"
                              :disabled="true"
                            />
                          </td>
                          <td>
                            <va-select
                              class="mt-1"
                              v-if="!field.getFlag"
                              v-model="field.expiry_date"
                              textBy="id"
                              :options="end_dateArr"
                            />
                            <va-input
                              class="mt-1"
                              v-if="field.getFlag"
                              v-model="field.expiry_date"
                              type="text"
                              :disabled="true"
                            />
                          </td>
                          <td>
                            <va-button
                              v-if="addonbundlefields.length != 1"
                              class="content_width toCenter"
                              flat color="danger"
                              @click.prevent="removeIPTVField(i)"
                              icon="entypo entypo-cancel" />
                          </td>
                          <td>
                            <va-button
                              v-if="(addonbundlefields.length - 1 == i) && !no_iptv_change_flag"
                              class="content_width toCenter"
                              flat color="primary"
                              @click.prevent="fields_length++"
                              icon="entypo entypo-plus" />

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </slot>
              </va-modal>

              <div class="mt-2">
                <span v-if="((checkIptv || !checkIptv) && allowed_device && stbFlag && serialNoFlag) || serial_mac_flag && isUpdate"  class="va-form-label va-form-required-label">Serial Number</span>
                <va-select
                  v-if="(((checkIptv ||!checkIptv) && isCreate) || (!serial_mac_flag && isUpdate)) && allowed_device && stbFlag && serialNoFlag"
                  placeholder="Select STB"
                  v-model="serial_no"
                  searchable
                  textBy="serialAndMac"
                  :options="stbArr"
                  :error="!!serialNoErrors.length"
                  :error-messages="serialNoErrors"
                />
                <va-input
                  v-if="serial_mac_flag && isUpdate"
                  v-model="serialAndMac"
                  placeholder="Enter Serial Number"
                  type="text"
                  :disabled="true"
                />
              </div>
              <span v-if="checkIptv && ncf_pay > 0" class="va-form-label va-form-required-label">NCF Cost</span>
              <va-input
                v-if="checkIptv && ncf_pay > 0"
                v-model="ncf_pay"
                type="text"
                :disabled="true"
              />
              <span class="va-form-label va-form-required-label">Renewal Types</span>
              <div class="row ml-2">
                <va-radio-button
                  option="no_auto_renew"
                  v-model="renewal_type"
                  label="No Auto Renew"
                  color="warning"
                />
                <va-radio-button
                  option="renew_initial_bundle"
                  v-model="renewal_type"
                  label="Renew Initial Bundle"
                />
                <va-radio-button
                  option="renewal_customer_bundle"
                  v-model="renewal_type"
                  label="Renew Customer Bundle"
                />
              </div>
              <div class="mt-2" v-if="isUpdate">
                <span class="va-form-label">IPTV</span>
                <div><va-button v-if="isUpdate" outline @click.prevent="iptvlists()">Bundle Lists</va-button></div>
              </div>
              <va-modal
                v-model="showValidity"
                title="Bundle Update"
                size='small'
                okText="Ok"
                :message="change_msg"
                cancelText="Cancel"
                @ok="validityConfirmation = !validityConfirmation"
                @cancel="cancel()"
                no-outside-dismiss
              />
              <va-modal
                v-model="validityConfirmation"
                title="Date Confirmation"
                size="small"
                message="Whether you want to extend the expiry date of the addon bundles to the current subscription expiry date"
                okText="Proceed"
                cancelText="No"
                @ok="proceedSub()"
                @cancel="unproceedSub()"
                no-outside-dismiss
              />

              <!--  <va-modal
                        class="flex xs12"
                        v-model="showValidity"
                        title="Bundle Update"
                        size='small'
                        okText="Ok"
                        :message="change_msg"
                        cancelText="Cancel"
                        @ok="checkexternalapp()"
                        @cancel="cancel()"
                        noEscDismiss>
                      </va-modal> -->
              <div style="text-align: right;">
                <label v-if="isUpdate && msgFlag" style="background-color: #8ce78c;">{{msg}}</label><br><br>
                <va-button v-if="isUpdate && !msgFlag && !subscriber_flag" type="submit" class="my-0"
                  @click.prevent="updateSubscription()">Update</va-button>
                <va-button v-if="isUpdate && msgFlag" type="submit" class="my-0"
                  @click.prevent="adjustPayEdit()">{{btn}}</va-button>
                <va-button v-if="isUpdate && !msgFlag && subscriber_flag" type="submit" class="my-0"
                  @click.prevent="updateSubscription()">Update & Renew</va-button>
                <va-button  v-if="isUpdate" class="my-0" type="submit" @click.prevent="list()">Cancel</va-button>
              </div>
            </form>
          </div>
        </va-card>
      </div>
      <!-- newcode -->
    </div>
    <!-- <div v-if="bundle"> -->
    <div class="container2" v-if="isshowSecond" id="page2">
      <div class="row" v-if="isNewCard" id="top">
        <div class="col-lg-3">
          <p class="head">{{topName}}</p>
          <va-tabs v-model="tabValue" style="margin-left: 24px;">
            <va-tab class="tab" v-for="(title, index) in tabTitles" :key="title" @click="tabClicked(index)"
              :class="{ 'active-tab': index == tabValue }">
              {{title}}
            </va-tab>
          </va-tabs>
          <div v-model="tabData" class="tabname">
            <div v-if="columns.length === 0">
            </div>
            <div v-for="(columnValues, columnIndex) in columns" :key="columnIndex" class="column1" :style="{ 'border-right':columnValues.length > 1 && columnIndex !==2 ? '2px solid lightgrey' : 'none', margin: '10px' }">
              <div v-for="(channel, rowIndex) in columnValues">

                {{ channel }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="isNewCard1" id="bottom">
        <div class="col-lg-3">
          <div class="card-header">
            <div class="card-header-title">Price Details</div>
            <div class="card-header-amount">Total MRP: {{total_cost.toLocaleString()}}</div>
          </div>
          <div class="card-item-container">
            <div class="card-item" v-if="bundle">
              <div class="card-item-title" style="margin-top: 5px;">{{current_bun_name}}</div>
              <div class="card-item-amount" style="color: green;">₹ {{current_bun_cost.toLocaleString()}}
              </div>
            </div>
            <div class="card-item" v-if="isShowiptv" v-for="(item, index) in addonbundle">
              <div class="card-item-title">{{item.bundle_name}}</div>
              <div class="card-item-amount">₹ {{item.all_bun_cost}}</div>
            </div>
            <div class="card-item" v-if="upgradebundle">
              <div class="card-item-title">{{upgrade_bun_name}}</div>
              <div class="card-item-amount">₹ {{upgrade_bun_cost.toLocaleString()}}</div>
            </div>
            <div class="card-item" v-if="checkIptv && ncf_pay>0">
              <div class="card-item-title">NCF cost</div>
              <div class="card-item-amount">₹ {{ncf_pay.toLocaleString()}}</div>
            </div>
            <div class="card-item" v-if="isPreCount">
              <div class="card-item-title">Counts Available</div>
              <div class="card-item-amount">{{available_count}}</div>
            </div>
          </div>
          <div class="card-footer">
            <div class="card-footer-title">Total Payable (Exclusive of GST)</div>
            <div class="card-footer-amount">₹ {{total_payable.toLocaleString()}}</div>
          </div>
        </div>
      </div>
      <div class="row" style="display: flex; justify-content: center;">
        <div>
          <label v-if="isCreate && msgFlag"
            style="background-color: #8ce78c; text-align: right;">{{msg}}</label><br>
          <!-- <label v-if="isUpdate && msgFlag" style="background-color: #8ce78c;">{{msg}}</label><br> -->
          <va-button v-if="isCreate && !msgFlag && !pre_flag" @click.prevent="createSubscription('initial')"
            type="submit" class="my-0">Proceed to pay</va-button>

          <va-button v-if="isCreate && msgFlag" type="submit" class="my-0"
            @click.prevent="adjustablePay()">{{btn}}</va-button>
          <va-button v-if="subscriber_flag && msgFlag" type="submit" class="my-0"
            @click.prevent="adjustPayEdit()">{{btn}}</va-button>
          <va-button class="my-0" type="submit" @click.prevent="list()">Cancel</va-button>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
  <!-- createbox -->

  <va-card title="Subscription List" v-else-if="isShowCust">
    <template slot="actions">
      <va-button type="submit" color="warning" icon="fa fa-arrow-circle-left" class="my-0" @click="backPage()">Back</va-button><br>
    </template>
    <template>
      <div class="container row">
        <div class="left" style="margin-top: 17px;">S.No</div>
        <div class="labelwidth left">Name</div>
        <!-- <div class="labelwidth left">Email</div> -->
        <div class="labelwidth left">Mobile</div>
        <div v-if="checkIptv" class="labelwidth left">STB</div>
        <div class="labelwidth left">Bundle</div>
        <div class="labelwidth left">Mode</div>
      </div>
      <!-- <div class="row align--center"></div> -->
      <fieldset class="row align--center" v-for="(row, i) in cust_list" :key="i">
        <label v-if="((i+1)<=9)" style="margin-right: 9px;" class="left">{{i+1}}</label>
        <label v-if="((i+1)>9)" class="left">{{i+1}}</label>
        <input
          v-if="!row.flag"
          class="width left"
          v-model="row.name"
          type="text"
          :error="!!nameErrors.length"
          :error-messages="nameErrors"
        />
        <input
          v-if="row.flag"
          class="width left activeClass"
          v-model="row.name"
          type="text"
          :error="!!nameErrors.length"
          :error-messages="nameErrors"
        />
        <input
          v-if="!row.flag"
          class="width left"
          v-model="row.mobile"
          type="text"
          :error="!!mobileErrors.length"
          :error-messages="mobileErrors"
        />
        <input
          v-if="row.flag"
          class="width left activeClass"
          v-model="row.mobile"
          type="text"
          :error="!!mobileErrors.length"
          :error-messages="mobileErrors"
        />
        <div>
          <va-select
            v-if="((!row.flag && checkIptv) || (!row.flag)) && stbFlag"
            style="width: 200px;"
            searchable
            placeholder="Select STB"
            v-model="row.serial_number"
            textBy="serialAndMac"
            :options="stbArr"
            :error="!!serialNoErrors.length"
            :error-messages="serialNoErrors"
          />
          <va-select
            v-if="(row.flag && checkIptv) || (row.flag) && stbFlag"
            style="width: 200px;"
            class = "activeClass"
            placeholder="Select STB"
            v-model="row.serial_number"
            textBy="serialAndMac"
            :options="stbArr"
            :error="!!serialNoErrors.length"
            :error-messages="serialNoErrors"
          />
        </div>
        <input
          v-if="!row.flag"
          class="width left"
          v-model="row.bundle"
          type="text"
          disabled="true"
          :error="!!bundleErrors.length"
          :error-messages="bundleErrors"
        />
        <input
          v-if="row.flag"
          class="width left activeClass"
          v-model="row.bundle"
          type="text"
          disabled="true"
          :error="!!bundleErrors.length"
          :error-messages="bundleErrors"
        />
        <input
          v-if="!row.flag"
          class="width left"
          v-model="row.mode"
          disabled="true"
          type="text"
          :error="!!modeErrors.length"
          :error-messages="modeErrors"
        />
        <input
          v-if="row.flag"
          class="width left activeClass"
          v-model="row.mode"
          disabled="true"
          type="text"
          :error="!!modeErrors.length"
          :error-messages="modeErrors"
        />
        <va-button flat small color="red" icon="fa fa-remove" @click="delCustList(row,i)" class="ma-0"></va-button>
      </fieldset><br>
      <div style="text-align: right;">
        <label v-if="msgFlag" style="background-color: #8ce78c;">{{msg}}</label><br><br>
        <va-button type="submit" v-if="!msgFlag" class="my-0" @click.prevent="createSubscription('initial')">Submit</va-button>
        <va-button v-if="msgFlag" type="submit" class="my-0" @click.prevent="adjustablePay()">{{btn}}</va-button>
        <va-button type="submit" class="my-0" @click.prevent="backPage()">Cancel</va-button><br>
      </div>
    </template>
  </va-card>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'
import Datepicker from 'vuejs-datepicker'
import { exportToFile } from '../../i18n/exportFile.js'
import { debounce } from 'lodash'
import REGEX from '../../i18n/regex_pattern'
Vue.use(vueResource)
function splitChannels (channelString) {
  return channelString.split(',')
}

export default {
  name: 'subscription',
  components: { Datepicker },
  beforeCreate () {
    var vm = this
    this.$http.get(config.menu.host + '/operator_setting').then(oper => {
      this.operatorpre = oper.body[0].pre_activation
      this.iptv_enabled = (oper.body[0].ncf_flag) ? oper.body[0].ncf_flag : false
      this.pre_activation_bundles = oper.body[0].pre_activation
      this.pre_activation_flag = oper.body[0].pre_activation_flag
      var op_pre_arr = []
      if (this.pre_activation_flag && this.pre_activation_bundles.length > 0) {
        var current_date = new Date()
        this.pre_activation_bundles.map(function (pre_bundle) {
          if ((pre_bundle.free_count > 0 && pre_bundle.used_count < pre_bundle.free_count) && (new Date(pre_bundle.end_date).getTime() > current_date.getTime()) && (new Date(pre_bundle.start_date).getTime() <= current_date.getTime())) {
            op_pre_arr.push(pre_bundle.bundle_name.bundle_id)
          }
        })
      }
      this.$http.get(config.menu.host + '/bundle/operator/content').then(resp => {
        this.moqbundle = resp.body.moq
        this.allBundleList = resp.body.base
        var base_input = []
        vm.bundleArrAll = []
        resp.body.base.filter(function (item, i) {
          item.id = i + 1
          item.addon = false
          item.base = true

          if (item.bundle_type == 'groupedpackage') {
            var iter = []
            var key = item.bundle_name
            item.bundle_grouped_packages.forEach(function (groupedPackage) {
              if (groupedPackage.iptv) {
                iter.push(groupedPackage.bundle_name)
                vm.groupediptv_list[key] = iter
              }
            })
          }
          if (oper.body[0].enable_bundle_creation) {
            if (oper.body[0].enable_reseller_bundle_creation) {
              if (item.bundle_type == 'resellerpackage') {
                vm.bundleArrAll.push(item)
              }
              if (op_pre_arr.indexOf(item.bundle_id) != -1) {
                vm.bundleArrAll.push(item)
              }
            } else {
              if (op_pre_arr.indexOf(item.bundle_id) != -1) {
                vm.bundleArrAll.push(item)
              }
              vm.bundleArrAll.push(item)
            }
          } else if (oper.body[0].enable_reseller_bundle_creation) {
            if (item.bundle_type == 'resellerpackage') {
              vm.bundleArrAll.push(item)
            }
            if (op_pre_arr.indexOf(item.bundle_id) != -1) {
              vm.bundleArrAll.push(item)
            }
          } else {
            vm.bundleArrAll.push(item)
          }
        })
        this.$http.get(config.menu.host + '/bundle/get/addon').then(respo => {
          this.addonbundleArrAll = respo.body.filter(function (pro, i) {
            pro.id = i + 1
            pro.addon = true
            pro.base = false
            pro.addon_status = true
            if (oper.body[0].enable_bundle_creation) {
              if (oper.body[0].enable_reseller_bundle_creation) {
                return pro
              } else {
                return pro.bundle_type == 'bundlepackage'
              }
            } else if (oper.body[0].enable_reseller_bundle_creation) {
              return pro
            } else {
              return pro
            }
          })
        })
        this.$http.get(config.menu.host + '/bundle/global/new/pre_active').then(global_preactive_bundle => {
          this.grouped_bundle = global_preactive_bundle.body
          this.grouped_bundle.map(function (data) {
            if (data.bundle_type == 'groupedpackage') {
              var iter = []
              var key = data.bundle_name
              data.bundle_grouped_packages.forEach(function (groupedPackage) {
                if (groupedPackage.iptv) {
                  iter.push(groupedPackage.bundle_name)
                  vm.groupediptv_list[key] = iter
                }
              })
            }
          })
        })
        this.getAllSubscription()
      }, function (err) {
        this.$http.get(config.menu.host + '/bundle').then(resp => {
          var grp_bundle = resp.body
          grp_bundle.map(function (data) {
            if (data.bundle_type == 'groupedpackage') {
              var iter = []
              var key = data.bundle_name
              data.bundle_grouped_packages.forEach(function (groupedPackage) {
                if (groupedPackage.iptv) {
                  iter.push(groupedPackage.bundle_name)
                  vm.groupediptv_list[key] = iter
                }
              })
            }
          })
        })

        this.getAllSubscription()
      })
    })
  },
  data () {
    return {
      totalPages: 0,
      selectOtns: {
        icon: 'entypo entypo-check',
        click: this.selectAll,
        value: 'Select All',
        color: 'primary',
      },
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      activeClass: 'background-color:red',
      isshowgrid: false,
      isshowForm: false,
      isForm: false,
      checkboxFlag: false,
      isCreate: false,
      isCustPresent: false,
      isUpdate: false,
      showRemoveModal: false,
      showStatusChange: false,
      autorenewal: true,
      importModal: false,
      bulkData: false,
      checkbox_value: false,
      msgFlag: false,
      make_import: false,
      isShowCust: false,
      bulkCreate: false,
      ncf_flag: false,
      ncf_already_added: false,
      pre_subscription: false,
      get_pre_activation: false,
      check_pre_bundle: false,
      pre_flag: false,
      ncf_pay: '',
      name: '',
      serial_no: '',
      migrate: false,
      migrateData: '',
      serial_number: '',
      filter: '',
      opfilter: '',
      groupediptv_list: {},
      mobile: '',
      commonRemove: '',
      commonmigrate: '',
      statusRemove: '',
      bundle: '',
      upgradebundle: '',
      isFullWidth: false,
      isshowSecond: false,
      isUpgrade: false,
      isiptv: false,
      isNewCard: false,
      isNewCard1: false,
      isPreCount: false,
      isShowiptv: false,
      mode: '',
      btn: '',
      message: '',
      messages: '',
      operatorpre: [],
      statusMessage: '',
      msg: '',
      title: '',
      already_updated: false,
      status: 'New',
      is_active: 0,
      addonbundle: [],
      addonbundleArr: [],
      stbArr: [],
      subscriptionData: [],
      filterErrors: [],
      operFilterErrors: [],
      addonbundleErrors: [],
      serialNoErrors: [],
      operFilterArr: [],
      filterArr: [{ id: 'New' }, { id: 'Inactive' }, { id: 'Active' }, { id: 'Deactive' }, { id: 'Expires Today' }],
      nameErrors: [],
      modeArr: [{ id: 'One Month' }, { id: 'Three Month' }, { id: 'Six Month' }, { id: 'Twelve Month' }],
      cust_list: [],
      importData: [],
      mobileErrors: [],
      bundleArr: [],
      opGlobalArr: [],
      priorityErrors: [],
      bundleErrors: [],
      statusErrors: [],
      cancelArr: [],
      modeErrors: [],
      statusArr: [{ id: 'New' }, { id: 'Active' }, { id: 'Deactive' }],
      keyObj: {
        'One Month': 'price_one_month',
        'Three Month': 'price_three_month',
        'Six Month': 'price_six_month',
        'Twelve Month': 'price_twelve_month',
      },
      valueObj: {
        'One Month': 29,
        'Three Month': 89,
        'Six Month': 179,
        'Twelve Month': 364,
      },
      modeObj: {
        'One Month': 'one_month_credit',
        'Three Month': 'three_month_credit',
        'Six Month': 'six_month_credit',
        'Twelve Month': 'twelve_month_credit',
      },
      stb: true,
      app: false,
      renewal_type: 'no_auto_renew',
      bundle_type: '',
      bundle_cost: 0,
      exist_base_bundleid: '',
      base_bundle_updation: false,
      showValidity: false,
      change_msg: '',
      subscriber_flag: false,
      iptv_enabled: false,
      tabValue: 0,
      tabTitles: ['Pay TV', 'Zee5 Premium', 'FTA'],
      channels: [],
      channel: '',
      tabData: '',
      topName: '',
      // org_stb_flag: false,
      iscreated: false,
      allowed_device: '',
      stbFlag: false,
      serial_mac_flag: false,
      serialNoFlag: false,
      swapChangeModal: false,
      existing_serial_no: '',
      isAddon: false,
      addonData: '',
      columns: '',
      fta: '',
      pay: '',
      zee: '',
      initCall: false,
      pre_activation_bundles: [],
      pre_activation_flag: false,
      addonbundlefields: [{
        addonbundle: '',
        start_date: '',
        expiry_date: '',
      }],
      backup_addonbundlefields: [],
      isShowIptv: false,
      disabledDate: {
        to: null,
      },
      fields_length: 0,
      backup_addonbundleArr: [],
      end_dateArr: [],
      validityConfirmation: false,
      available_count: '',
      total_payable: '',
      total_cost: '',
      current_bun_name: '',
      current_bun_cost: '',
      upgrade_bun_name: '',
      upgrade_bun_cost: '',
      iptv_bun_name: '',
      iptv_bun_cost: '',
      check_mode: '',
      // extended_external_app: false,
      external_package_length: Number(''),
      extended_subscription: false,
      statusMessage: '',
      renewConfirm: false,
      renewal_entity: '',
      pre_renew: false,
      backupMode: '',
      no_iptv_change_flag: false,
      renew_type: '',
    }
  },
  watch: {
    fields_length () {
      this.addonbundlefields.push({ bundle_name: '', start_date: this.today(), expiry_date: '' })
      this.end_dateArr = []
      this.bundle_check_status()
    },
    tabValue () {
      if (this.tabValue == 0) {
        this.getpaytv()
      } else if (this.tabValue == 1) {
        this.getzee5premium()
      } else if (this.tabValue == 2) {
        this.getfta()
      }
    },
  },
  computed: {
    checkIptv () {
      var vm = this
      vm.msgFlag = false
      if (vm.isCreate) {
        vm.serial_no = ''
        vm.ncf_pay = ''
        vm.ncf_bundle = ''
      }
      var basebundle = [vm.bundle]
      if (vm.isUpdate) {
        if (typeof (vm.bundle) === 'object') {
          var check_bundle = vm.bundle.bundle_name
        } else {
          var check_bundle = vm.bundle
        }
        var filter = this.allBundleList.filter(function (prop) {
          return (prop.bundle_name == check_bundle)
        })
        basebundle = filter
      }
      var iptvFlag = false
      var id
      loop1:
      for (var i = 0; i < basebundle.length; i++) {
        if (basebundle[i].bundle_type == 'resellerpackage') {
          var res_pack = basebundle[i].bundle_reseller_custom_packages
          for (var x = 0; x < res_pack.length; x++) {
            if (res_pack[x].iptv) {
              iptvFlag = true
              id = basebundle[i].bundle_id
              // break loop1
            }
          }
        } else if (basebundle[i].bundle_type == 'groupedpackage') {
          if (basebundle[i].bundle_grouped_packages) {
            var grp_pack = basebundle[i].bundle_grouped_packages
            for (var y = 0; y < grp_pack.length; y++) {
              if (grp_pack[y].iptv) {
                iptvFlag = true
                id = basebundle[i].bundle_id
                if (this.allowed_device == 'mobile+tv+stb') {
                  this.stbSelect('mobile+tv+stb', 'iptv_flag')
                } else if (this.allowed_device == 'mobile+tv') {
                  this.stbSelect('mobile+tv', 'iptv_flag')
                } else if (this.allowed_device == 'stb') {
                  this.stbSelect('stb', 'iptv_flag')
                }
                // break loop1
              }
            }
          }
        } else {
          if (basebundle[i].iptv) {
            iptvFlag = true
            id = basebundle[i].bundle_id
            break loop1
          }
        }
      }

      if (!iptvFlag) {
        loop2:
        for (var j = 0; j < vm.addonbundle.length; j++) {
          if (vm.addonbundle[j].iptv) {
            iptvFlag = true
            id = vm.addonbundle[j].bundle_id
            if (this.allowed_device == 'mobile+tv+stb') {
              this.stbSelect('mobile+tv+stb', 'iptv_flag')
            } else if (this.allowed_device == 'mobile+tv') {
              this.stbSelect('mobile+tv', 'iptv_flag')
            } else if (this.allowed_device == 'stb') {
              this.stbSelect('stb', 'iptv_flag')
            }
            break loop2
          }
        }
      }
      if (iptvFlag) {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        vm.$http.get(config.menu.host + '/bundle/ncf/bundle').then(resp => {
          loader.hide()
          vm.ncf_bundle = resp.body
          var mode_data = this.mode.id ? this.mode.id : this.mode
          var arg = this.keyObj[mode_data]
          vm.ncf_pay = vm.ncf_bundle[arg]
          if (this.isUpdate && vm.ncf_bundle.bundle_id) {
            this.sub_bundle.map(function (like) {
              if (like.bundle_id == vm.ncf_bundle.bundle_id) {
                vm.ncf_already_added = true
              }
            })
          }
          if (this.checkIptv) {
            this.total_cost = Number(this.current_bun_cost)

            if (vm.addonbundle && vm.addonbundle.length > 0) {
              for (var i = 0; i < vm.addonbundle.length; i++) {
                this.total_cost += this.addonbundle[i].all_bun_cost
                if (i + 1 == this.addonbundle.length) {
                  if (this.ncf_pay) {
                    this.total_cost += Number(this.ncf_pay)
                  }
                }
              }
              this.total_payable = this.total_cost
            } else if (!this.bundle.pre_active) {
              this.total_cost += this.ncf_pay
              this.total_payable = this.total_cost
            }
          } else {
            this.total_cost = Number(this.current_bun_cost)
            this.total_payable = this.total_cost
          }
        })

        if (this.isUpdate) {
          this.stb = true
        }
      }
      this.stbSelect(this.allowed_device, 'iptv_flag')
      return iptvFlag
    },
    actionBtnsDisabled () {
      const cust_array_selected = this.subscriptionData.filter(cust => cust.checkbox_value)
      if (cust_array_selected.length > 0) {
        return false
      }
      return true
    },
    formReady () {
      return !this.nameErrors.length && !this.mobileErrors.length && !this.bundleErrors.length && !this.modeErrors.length
    },
    fields () {
      if (Vue.$cookies.get('userRole') === 'OPERATOR' || Vue.$cookies.get('userRole') === 'HEPI_OPERATOR'  || Vue.$cookies.get('userRole') === 'SUPPORT' ) {
        var columns = [
          {
            name: 'sno',
            title: 'S.NO',
            width: '2%',
          }, {
            name: '__slot:checkbox',
          }, {
            name: 'name',
            title: 'Name',
          },
          {
            name: 'email',
            title: 'Email',
          },
          {
            name: 'mobile',
            title: 'Mobile',
          },
          {
            name: 'mode',
            title: 'Mode',
          },
          {
            name: 'status',
            title: 'Status',
          },
          {
            name: 'serial_no',
            title: 'Serial Number',
          },
          {
            name: 'mac_address',
            title: 'MAC Address',
          },
          {
            name: '__slot:activated_on',
            title: 'Activated Date',
          },
          {
            name: '__slot:expires_on',
            title: 'Expiry Date',
          },
          {
            name: 'bundle',
            title: 'Base Bundle',
            width: '10%',
          },
          {
            name: '__slot:addon_grid',
            title: 'IPTV',
          },
          {
            name: '__slot:serialChange',
            title: 'Swaping',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
          {
            name: '__slot:is_active',
            title: 'is active',
          },
        ]
      } else {
        var columns = [
          {
            name: 'sno',
            title: 'S.NO',
            width: '2%',
          }, {
            name: '__slot:checkbox',
          }, {
            name: 'name',
            title: 'Name',
          },
          {
            name: 'org_name',
            title: 'Organization',
          },
          {
            name: 'email',
            title: 'Email',
          },
          {
            name: 'mobile',
            title: 'Mobile',
          },
          {
            name: 'mode',
            title: 'Mode',
          },
          {
            name: 'status',
            title: 'Status',
          },
          {
            name: 'serial_no',
            title: 'Serial Number',
          },
          {
            name: 'mac_address',
            title: 'MAC Address',
          },
          {
            name: '__slot:activated_on',
            title: 'Activated Date',
          },
          {
            name: '__slot:expires_on',
            title: 'Expiry Date',
          },
          {
            name: 'bundle',
            title: 'OTT Bundle',
            width: '10%',
          },
          {
            name: '__slot:addon_grid',
            title: 'IPTV',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
        ]
      }
      if(Vue.$cookies.get('userRole') === 'ADMIN') columns.push({name: '__slot:is_active',title: 'is active'});
      return columns;
    },
    filteredData () {
      return this.subscriptionData
    },
  },
  methods: {
    isWithin10DaysBeforeExpiry (expireDate) {
      const TEN_DAYS_IN_MILLISECONDS = 10 * 24 * 60 * 60 * 1000
      const today = new Date()
      const expiration = new Date(expireDate)
      // Calculate the difference in milliseconds
      const differenceInMs = expiration - today

      // Check if the expiration date is within 10 days from today
      return differenceInMs <= TEN_DAYS_IN_MILLISECONDS && expiration > today
    },
    tabClicked (index) {
      switch (index) {
        case 0:
          this.getpaytv()
          break
        case 1:
          this.getzee5premium()
          break
        case 2:
          this.getfta()
          break
        default:
          break
      }
    },
    convert (str) {
      var date = new Date(str)
      var mnth = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('-')
    },
    today () {
      var today = new Date()
      return this.convert(new Date(today))
    },
    endDateCalc (rowData) {
      this.end_dateArr = []
      this.mode = (typeof (this.mode) === 'object') ? this.mode.id : this.mode
      if ((new Date(rowData.start_date).getTime() >= new Date(this.expiry).getTime()) && this.status != 'Deactive') {
        rowData.start_date = ''
        return Vue.notify({ text: 'Start date should be lesser than the Expiry date', type: 'error' })
      } else {
        var expiry_date = this.expiry.split(' ')[0] + ' Actual-Expiry'
        if (this.mode == 'One Month' && this.status != 'Deactive') {
          this.end_dateArr.push(expiry_date)
        } else {
          var today = new Date()
          const dateCheck = parseInt((new Date(this.expiry).getTime() - new Date(today).getTime()) / (1000 * 60 * 60 * 24)) + 1
          if (dateCheck > 30 || this.status == 'Deactive') {
            if (this.status == 'Deactive') {
              var obj = {
                'One Month': 29,
                'Three Month': 89,
                'Six Month': 179,
                'Twelve Month': 364,
              }
              var days = (obj[this.mode])
              var today_date = new Date()
              var de_expiryDate = new Date(today_date.setDate(today_date.getDate() + days)).setHours(23, 59, 59, 999)
              var deactive_end_date = this.convert(new Date(de_expiryDate)) + ' Actual-Expiry'
              this.end_dateArr.push(deactive_end_date)
            } else {
              this.end_dateArr.push(expiry_date)
            }
            var start_date = new Date(rowData.start_date)
            var expiryDate = new Date(start_date.setDate(start_date.getDate() + 29)).setHours(23, 59, 59, 999)
            var bundle_end_date = this.convert(new Date(expiryDate)) + ' Bundle-Expiry'
            if (this.status == 'Deactive' && this.mode == 'One Month') {
            } else {
              this.end_dateArr.push(bundle_end_date)
            }
          } else {
            this.end_dateArr.push(expiry_date)
          }
        }
      }
    },
    bundle_check_status () {
      var checkArr = this.addonbundlefields.map(function (map) {
        if (typeof (map.bundle_name) === 'object') {
          return map.bundle_name.bundle_name
        } else {
          return map.bundle_name
        }
      })
      var arr = []
      this.backup_addonbundleArr.map(function (data) {
        if (!checkArr.includes(data.bundle_name)) {
          arr.push(data)
        }
      })
      this.addonbundleArr = arr
    },
    removeIPTVField (index) {
      if (this.addonbundlefields.length == 1 && index == 0) {
        this.addonbundlefields.push({ bundle_name: '', start_date: this.today(), end_date: '' })
      }
      this.addonbundlefields = this.addonbundlefields.filter((_field, i) => i != index)
      this.bundle_check_status()
    },
    iptvlists () {
      if (this.addonbundlefields.length == 0) {
        this.addonbundlefields.push({ bundle_name: '', start_date: this.today(), expiry_date: '' })
      };
      this.isShowIptv = true
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      this.disabledDate.to = yesterday
    },
    cancelPTVSpace () {
      if (typeof (this.backup_addonbundlefields) === 'string') {
        this.addonbundlefields = JSON.parse(this.backup_addonbundlefields)
        this.isShowIptv = false
      };
    },
    finish (type) {
      var flag = false; var vm = this
      if (type == 'direct') {
        if (vm.addonbundlefields.length == 1 && vm.addonbundlefields[0].bundle_name == '') {
          flag = false
        } else {
          this.addonbundlefields.map(function (data) {
            if ((!data.bundle_name || !data.start_date || !data.expiry_date)) {
              flag = true
            }
          })
        }
      }
      if (flag) {
        Vue.notify({ text: 'Please Fill the form correctly', type: 'error' })
        this.isShowIptv = true
      } else {
        this.isShowIptv = false
        var emptyArr = []
        this.addonbundlefields.map(function (map_value) {
          if (typeof (map_value.bundle_name) !== 'string') {
            var fixedDate = map_value.expiry_date.split(' ')[0]
            map_value.bundle_name.start_date = new Date(map_value.start_date).setHours(0, 0, 0, 0)
            map_value.bundle_name.expiry_date = new Date(fixedDate).setHours(23, 59, 59, 999)
            if (!emptyArr.some(function (add) { return add.bundle_id == map_value.bundle_name.bundle_id })) {
              emptyArr.push(map_value.bundle_name)
            }
          } else {
            if (!emptyArr.some(function (add) { return add.bundle_id == map_value.bundle_id })) {
              emptyArr.push(map_value)
            };
          };
        })
        this.addonbundle = emptyArr
      };
    },
    selectAll () {
      let count = 0
      this.subscriptionData.map((cust, i) => {
        if (cust.transcoding_status == 'inprogress') return
        cust.checkbox_value = true
        count++
      })
      if (!count) return
      this.subscriptionData.map(cust => cust.checkbox_value = true)
      this.selectOtns = {
        icon: 'entypo entypo-cancel',
        click: this.deSelectAll,
        value: 'De Select All',
        color: 'danger',
      }
      clearInterval(this.interval)
    },
    confirmMigration (data) {
      this.migrate = true
      this.migrateData = data
      this.commonmigrate = 'migration'
      this.messages = 'Are you sure to migrate the subscription ?'
    },
    migration (data) {
      var payload = {
        subscription_id: data.subscription_id,
        serial_number: data.serial_no,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/subscription/migration', payload).then(resp => {
        loader.hide()

        if (resp && resp.body) {
          Vue.notify({ text: resp.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    deSelectAll () {
      this.subscriptionData.map(cust => cust.checkbox_value = false)
      this.selectOtns = {
        icon: 'entypo entypo-check',
        click: this.selectAll,
        value: 'Select All',
        color: 'primary',
      }
      this.getAllSubscription()
    },
    addon (addonData) {
      var addBundle
      var gridpack = addonData.addon_grid
      var vm = this
      addonData.subscription_bundles.map(function (args) {
        if (vm.groupediptv_list[args.bundle_name]) {
          addBundle = vm.groupediptv_list[args.bundle_name]
        }
      })

      function replaceCommaLine (addBundle, gridpack) {
        let dataToArray = gridpack.split(',')
        if (addBundle !== undefined) {
          dataToArray = dataToArray.concat(addBundle)
        }
        const complete = dataToArray.map(function (item, i) {
          var datas = (i + 1) + '.  ' + item.trim()
          return datas
        })
        return complete.join('\n')
      }
      this.isAddon = true
      const availableData = addonData
      this.addonData = replaceCommaLine(addBundle, gridpack)
    },
    swapChange (data) {
      this.swapChangeModal = true
      this.serial_no = ''
      this.existing_serial_no = data.serial_no + ' - ' + data.mac_address
      var type = ((data.allowed_device.includes('stb')) || (data.checkIptv)) ? 'iptv_swag' : 'iptv_flag'
      this.stbSelect(data.allowed_device, type)
      this.subscription_id = data.subscription_id
    },
    swapSerial () {
      if (this.serial_no == '') {
        return Vue.notify({ text: 'Please select the serial number', type: 'error' })
      }
      var existing_unique = this.existing_serial_no.split('-')[0]
      var payload = {
        existing_serial_no: existing_unique,
        serial_no: this.serial_no.unique_id,
        mac_address: this.serial_no.mac_address,
        subscription_id: this.subscription_id,
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/subscription/emm_swap', payload).then(swapresp => {
        loader.hide()
        if (swapresp && swapresp.body) {
          Vue.notify({ text: swapresp.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    downloadFormat () {
      this.cancelImport()
      var rowKeys = {
        name: 'Name',
        // email: 'Email',
        mobile: 'Mobile',

      }
      var subscriber_list = []
      //      exportToFile(subscriber_list, rowKeys, 'SubscriptionFormat', 'CSV')
      // this.$http.post(config.menu.host + '/subscription/export', {}).then(resp => {
      exportToFile([], rowKeys, 'Subscription', 'CSV')
      // })
    },
    guid () {
      function s4 () {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
    },
    getAddon (data) {
      if (this.allowed_device == 'mobile+tv' && !this.checkIptv) {
        this.stbFlag = false
      }
      data.bundle_name.addon_status = 'progress'
      this.endDateCalc(data)
    },
    allowedType (select) {
      this.empty(null)
      if (this.isCreate || this.bulkData) {
        this.mode = ''
        this.bundle = ''
        this.addonbundle = ''
        this.bundleArr = []
        this.addonbundleArr = []
      }
      this.stbSelect(select, 'iptv_flag')
    },
    stbSelect (type, flag) {
      var swapCheckIptv = (flag === 'iptv_swag')
      this.$http.get(config.menu.host + '/subscription').then(res => {
        res.body.map(function (argument) {
          argument.serialAndMac = argument.unique_id + ' - ' + argument.mac_address
        })
        var stb_arg = []
        if (type == 'mobile+tv+stb' && (!this.checkIptv || this.checkIptv || swapCheckIptv)) {
          this.serial_no = ''
          this.stbFlag = true
          this.serialNoFlag = true
          res.body.map(function (arg) {
            if (arg.ott_supported) {
              stb_arg.push(arg)
            }
          })
          this.stbArr = stb_arg
        } else if (type == 'mobile+tv' && !this.checkIptv && !swapCheckIptv) {
          this.serial_no = ''
          this.stbFlag = false
        } else if (type == 'mobile+tv' && (this.checkIptv || swapCheckIptv)) {
          this.serial_no = ''
          this.stbFlag = true
          this.serialNoFlag = true
          res.body.map(function (arg) {
            if (!arg.ott_supported || arg.ott_supported == null || arg.ott_supported == 'NULL') {
              stb_arg.push(arg)
            }
          })
          this.stbArr = stb_arg
        } else if (type == 'stb' && (!this.checkIptv || this.checkIptv || swapCheckIptv)) {
          this.serial_no = ''
          this.stbFlag = true
          this.serialNoFlag = true
          res.body.map(function (arg) {
            if (arg.ott_supported) {
              stb_arg.push(arg)
            }
          })
          this.stbArr = stb_arg
        }
      })
    },
    exportFile () {
      const payload = {
        id: this.opfilter.org_id,
      }
      this.$http.post(config.menu.host + '/subscription/allexport/', payload).then(res => {
        var rowKeys = {
          name: 'Customer Name',
          mobile: 'Contact Number',
          email: 'Mail ID',
          mode: 'Mode',
          activated_on: 'Start Date',
          expires_on: 'End Date',
          serial_no: 'Serial Number',
          mac_address: 'MAC ID',
          status: 'Status',
          'subscriberOrg.org_name': 'Org Name',
          bundle: 'Bundle',
        }
        exportToFile(res.body, rowKeys, 'Subscription', 'CSV')
      })
    },
    confirm_renew (row, renew_type) {
      this.renewal_entity = (row == 'null') ? null : row
      this.pre_renew = true
      this.renewConfirm = false
      this.renew_type = renew_type
    },
    renew (data, type) {
      var domain = location.host
      var re_url = 'https://' + domain
      var payload = {
        id_array: [data.subscription_id],
        redirection_url: re_url,
        renewal_with_addon: type,
        renewal_type: this.renew_type,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/subscription/manualRenewal', payload).then(resp => {
        loader.hide()
        if (resp.body.url) {
          window.open(resp.body.url, '_self')
        } else {
          Vue.notify({ text: resp.body, type: 'success' })
          this.list()
        }
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
          return this.$router.push('transaction').catch(() => {})
        }
      })
    },
    select (cust) {
      this.subscriptionData[cust.id].checkbox_value = !this.subscriptionData[cust.id].checkbox_value
      const cust_array_selected = this.subscriptionData.filter(cust => cust.checkbox_value)
      if (cust_array_selected.length == 0) {
        this.selectOtns = {
          icon: 'entypo entypo-check',
          click: this.selectAll,
          value: 'Select All',
          color: 'primary',
        }
        this.getAllSubscription()
      } else {
        this.selectOtns = {
          icon: 'entypo entypo-cancel',
          click: this.deSelectAll,
          value: 'De Select All',
          color: 'danger',
        }
        clearInterval(this.interval)
      }
    },
    getRole () {
      return Vue.$cookies.get('userRole')
    },
    checkFilter (filter, page = 0) {
      this.term = ''
      var filt = filter.id ? filter.id : 'all'
      this.$http.get(config.menu.host + '/subscription/' + filt + '/' + this.perPage + '/' + page).then(resp => {
        let index = 0
        this.subscriptionData = []
        var subscription_array = this.subscriptionData
        this.totalPages = resp.body.count
        resp.body.rows.map(function (item) {
          var addon_grid = ''
          item.subscription_bundles.map(function (argument) {
            if (argument.addon) {
              if (addon_grid == '') {
                addon_grid = argument.bundle_name
              } else {
                addon_grid = addon_grid + ', ' + argument.bundle_name
              }
            }
          })
          item.addon_grid = addon_grid
          item.id = index++
          item.sno = index
          item.checkbox_value = false
          if (filt == 'all') {
            subscription_array.push(item)
          } else if (filt == 'Active' && item.status == 'Active') {
            item.logoutFlag = true
            subscription_array.push(item)
          } else if (filt == 'New' && item.status == 'New') {
            subscription_array.push(item)
          } else if (filt == 'Inactive' || filt.toLowerCase() == 'deactive' || filt.toLowerCase() == 'expires today') {
            subscription_array.push(item)
          }
        })
        this.isForm = false
        this.isshowForm = false
        this.isshowgrid = true
        this.checkboxFlag = !!((filt != 'Active' && filt != 'Inactive' && filt != 'New' && filt != 'all'))
        if (this.subscriptionData.length == 0) this.isCustPresent = false
        else this.isCustPresent = true
      }, errResponse => {
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    getOrgFilter (data, page = 0) {
      this.filter = ''
      this.term = ''
      if (data) {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.get(config.menu.host + '/subscription/oper/filter/' + data.org_id + '/' + this.perPage + '/' + page).then(resp => {
          loader.hide()
          let index = 0
          var start = (page - 1).toString()
          let num = (page == 0) ? 1 : Number(start + 1)
          this.totalPages = resp.body.count
          this.subscriptionData = resp.body.rows.map(function (item) {
            item.id = index++
            item.sno = num++
            item.checkbox_value = false
            var addon_grid = ''
            item.subscription_bundles.map(function (argument) {
              if (argument.addon) {
                if (addon_grid == '') {
                  addon_grid = argument.bundle_name
                } else {
                  addon_grid = addon_grid + ', ' + argument.bundle_name
                }
              }
              item.addon_grid = addon_grid
            })
            return item
          })
        })
      } else {
        this.getAllSubscription()
      }
    },
    resetFlag (data) {
      this.tabValue = 0
      this.getpaytv()
      if (!this.checkIptv && this.allowed_device == 'mobile+tv') { this.serialNoFlag = false }
      this.msgFlag = false
      var vm = this
      data.map(function (item) {
        item.bundle_reseller_custom_packages.map(function (addonbundle, i) {
          if (addonbundle.iptv == true) {
            vm.org_stb_flag = true
            vm.checkIptv = true
          } else {
            vm.org_stb_flag = false
          }
        })
      })
      if (!vm.ncf_flag) {
        vm.addonbundle.map(function (entry) {
          if (entry.iptv) {
            vm.ncf_flag = true
          }
        })
      }
      if (data[0]) {
        this.isShowiptv = true
        var keyObj = {
          'One Month': 'price_one_month',
          'Three Month': 'price_three_month',
          'Six Month': 'price_six_month',
          'Twelve Month': 'price_twelve_month',
        }
        var vm = this
        data.map(function (item, i) {
          if (item.bundle_type == 'resellerpackage') {
            vm.addonbundle[i].all_bun_cost = item.seller_cost
          } else {
            const bundle_mode = item.bundle_mode
            const mode_count = keyObj[bundle_mode]
            const bundle_price = item[mode_count]
            vm.addonbundle[i].all_bun_cost = Number(bundle_price)
          }
        })
      } else if (!data[0]) {
        this.iptv_bun_name = ''
        this.iptv_bun_cost = 0
        this.isShowiptv = false
        this.total_cost = Number(this.current_bun_cost)
        this.total_payable = this.total_cost
      }
    },
    cancelImport () {
      this.importModal = false
      this.make_import = false
      this.bulkData = false
      this.bulkCreate = false
      this.bundle = ''
      this.fta = ''
      this.zee = ''
      this.pay = ''
      this.channel = ''
      this.addonbundle = []
      this.mode = ''
      this.btn = ''
      this.msg = ''
      this.allowed_device = ''
      this.msgFlag = false
      this.importData = []
    },
    makeImport () {
      this.bulkData = true
      this.make_import = true
    },
    getData (bundle) {
      this.topName = bundle.bundle_name
      this.addonbundle = []
      this.channel = ''
      this.isiptv = false
      if (bundle && this.isCreate) {
        this.bundle = bundle
        // this.isCreate = true
        this.msgFlag = false
        this.pre_flag = false
        this.empty(bundle)
      } else {
        this.isshowSecond = false
        this.empty(null)
      }
      if (this.isCreate) {
        if (!bundle.bun_cost && bundle.pre_active) {
          this.check_pre_bundle = false
          this.addonbundle = []
          this.isNewCard = true
        } else if (!bundle.pre_active) {
          this.check_pre_bundle = false
          this.addonbundle = []
          this.isNewCard = true
        } else if (bundle.pre_active) {
          this.check_pre_bundle = true
          this.addonbundle = []
          this.isNewCard = true
        } else {
          this.check_pre_bundle = false
        }
      }
      var vm = this
      if (this.isCreate) {
        // this.allowed_device = ''
        this.checkIptv = false
      } else if (this.isUpdate) {
        this.stbSelect(this.allowed_device, 'iptv_flag')
      }
      if (bundle.bundle_id !== this.exist_base_bundleid) {
        this.base_bundle_updation = true
      }
      this.msgFlag = false
      this.ncf_flag = !!(bundle.iptv)
      var arg = this.keyObj[this.mode.id]
      this.selectedBundle = bundle
      this.amount = this.selectedBundle[arg]
      if (this.selectedBundle.bundle_type == 'custompackage') {
        this.bundle_type = 'custompackage'
        this.bundle_cost = this.selectedBundle.bundle_cost
      } else {
        this.bundle_type = 'package'
      }
      if (this.ncf_bundle) {
        this.ncf_pay = this.ncf_bundle[arg]
      }
      var current_date = new Date()
      var credit_check_flag = false
      if (this.pre_activation_bundles && this.pre_activation_bundles.length > 0) {
        this.pre_activation_bundles.map(function (pre_bundles) {
          var pre_bundle = pre_bundles.mode.id
          if (vm.mode.id == pre_bundles.mode.id && pre_bundles.free_count > pre_bundles.used_count && (new Date(pre_bundles.end_date).getTime() > current_date.getTime()) && (new Date(pre_bundles.start_date).getTime() <= current_date.getTime())) {
            credit_check_flag = true
          }
        })
      }
      this.tabValue = 0
      this.getpaytv()
      if (!credit_check_flag) {
        this.$http.get(config.menu.host + '/transaction/credit/calculation').then(resp => {
          this.creditForBundle = resp.body

          if (this.creditForBundle.status == 'failed') {
            Vue.notify({ text: 'please add credit to activate the subscription', type: 'error' })
            return this.$router.push('transaction').catch(() => {})
          }
        })
      }
    },
    empty (current_bundle) {
      var vm = this
      if (current_bundle == null) {
        this.available_count = ''
        this.current_bun_name = ''
        this.current_bun_cost = ''
        this.upgrade_bun_cost = ''
        this.upgrade_bun_name = ''
        this.upgradebundle = ''
        this.total_cost = 0
        this.total_payable = 0
        this.isFullWidth = false
        this.isNewCard = false
        this.isNewCard1 = false
        this.isshowSecond = false
        this.isUpgrade = false
        this.iptv = true
      } else if (current_bundle.pre_active && current_bundle.bun_cost) {
        this.isNewCard = true
        this.isNewCard1 = true
        this.isFullWidth = true
        this.isshowSecond = true
        this.isUpgrade = true
        this.isiptv = false
        this.isPreCount = true
        this.addonbundle = []
        this.available_count = current_bundle.available_count
        this.current_bun_name = current_bundle.bundle_name
        this.current_bun_cost = current_bundle.bun_cost
        this.total_cost = current_bundle.bun_cost
        this.total_payable = 0
      } else if (!current_bundle.pre_active && current_bundle.bundle_type == 'resellerpackage') {
        this.current_bun_name = current_bundle.bundle_name
        this.current_bun_cost = Number(current_bundle.seller_cost)
        this.upgrade_bun_cost = ''
        this.upgrade_bun_name = ''
        this.upgrade_bundle_flag = false
        this.upgradebundle = ''
        this.total_cost = current_bundle.seller_cost
        this.total_payable = this.total_cost
        this.isFullWidth = true
        this.isshowSecond = true
        this.isUpgrade = false
        this.isiptv = true
        this.isNewCard = true
        this.isNewCard1 = true
        this.isPreCount = false
      } else if ((current_bundle.pre_active && !current_bundle.bun_cost) || !current_bundle.pre_active) {
        var keyObj = {
          'One Month': 'price_one_month',
          'Three Month': 'price_three_month',
          'Six Month': 'price_six_month',
          'Twelve Month': 'price_twelve_month',
        }
        const bundle_mode = current_bundle.bundle_mode
        const mode_count = keyObj[bundle_mode]
        const bundle_price = current_bundle[mode_count]
        this.current_bun_name = current_bundle.bundle_name
        this.current_bun_cost = bundle_price
        this.upgrade_bun_cost = ''
        this.upgrade_bun_name = ''
        this.upgrade_bundle_flag = false
        this.upgradebundle = ''
        this.total_cost = bundle_price
        this.total_payable = this.total_cost
        this.isFullWidth = true
        this.isshowSecond = true
        this.isUpgrade = false
        this.isiptv = true
        this.isNewCard = true
        this.isNewCard1 = true
        this.isPreCount = false
      }
    },
    getupgrade (upgrade_bundle) {
      this.topName = upgrade_bundle.bundle_name
      if (upgrade_bundle == '') {
        this.topName = this.bundle.bundle_name
      }
      var vm = this
      this.tabValue = 0
      this.getpaytv()
      this.isCreate = true
      this.msgFlag = false
      this.pre_flag = false
      if (upgrade_bundle) {
        const numberMap = {
          'One Month': 1,
          'Three Month': 3,
          'Six Month': 6,
          'Twelve Month': 12,
        }
        const bundle_mode = this.check_mode.id
        const mode_count = numberMap[bundle_mode]
        const bundle_price = upgrade_bundle.cost_price
        const converted_cost = bundle_price * mode_count
        upgrade_bundle.base = true
        upgrade_bundle.add_on = false
        vm.upgrade_bun_name = upgrade_bundle.bundle_name
        vm.upgrade_bun_cost = converted_cost
        vm.total_cost = Number(converted_cost) + Number(this.bundle.bun_cost)
        vm.total_payable = Number(this.bundle.bun_cost) - Number(converted_cost)
        if (vm.total_payable < 0) {
          vm.total_payable = (vm.total_payable * -1)
        } else if (vm.current_bun_cost > vm.upgrade_bun_cost) {
          vm.total_payable = 0
        }
      } else if (this.bundle.pre_active && !upgrade_bundle) {
        vm.total_cost = Number(this.bundle.bun_cost)
        vm.total_payable = 0
      } else {
        vm.upgrade_bun_name = ''
        vm.upgrade_bun_cost = ''
        vm.total_cost = 0
        vm.total_payable = 0
        this.isNewCard = true
        this.isiptv = true
      }
    },
    checkCredit (mode) {
      this.check_mode = mode
      this.isFullWidth = false
      this.isUpgrade = false
      this.isiptv = false
      this.isshowSecond = false
      this.upgradebundle = ''
      this.tabData = ''
      this.current_bun_name = ''
      this.current_bun_cost = ''
      this.upgrade_bun_cost = ''
      this.upgrade_bun_name = ''
      this.total_cost = ''
      this.total_payable = ''
      this.available_count = ''
      this.isNewCard = false
      this.check_pre_bundle = false
      this.pre_subscription = false
      // if(mode.id !== this.backupMode) this.no_iptv_change_flag = true;
      this.no_iptv_change_flag = (mode.id !== this.backupMode)
      if ((this.allowed_device == '' && this.isCreate) || (this.allowed_device == '' && this.bulkData)) {
        this.mode = ''
        return Vue.notify({ text: 'Please select the allowed device', type: 'error' })
      }
      this.msgFlag = false
      this.bundle = ''
      this.addonbundle = []
      if (mode == '') {
        this.bundleArr = []
        this.addonbundleArr = []
      }
      var vm = this
      this.prename = []
      this.opGlobalArr = []
      var arg = this.keyObj[mode.id]
      var days = (this.valueObj[mode.id])
      var expiry = new Date()
      var dateExpiry = new Date(expiry.setDate(expiry.getDate() + days))
      this.changeExpiryDate = new Date(dateExpiry).getFullYear() + '-' + (new Date(dateExpiry).getMonth() + 1) + '-' + new Date(dateExpiry).getDate()
      var input_base_arr = []
      var opArr = []
      if (this.pre_activation_flag) {
        var current_date = new Date()
        this.pre_activation_bundles.map(function (pre_bundle) {
          vm.bundleArrAll.map(function (arg) {
            if (pre_bundle.bundle_name.bundle_id == arg.bundle_id && !pre_bundle.removed_flag) {
              if ((pre_bundle.free_count > 0 && pre_bundle.used_count < pre_bundle.free_count) && (new Date(pre_bundle.end_date).getTime() > current_date.getTime()) && (new Date(pre_bundle.start_date).getTime() <= current_date.getTime())) {
                if (!pre_bundle.removed_flag) {
                  opArr.push(pre_bundle.bundle_name.bundle_name)
                }
                arg.available_count = pre_bundle.free_count - pre_bundle.used_count
                arg.bun_cost = pre_bundle.pre_bundle_cost
                arg.bundle_mode = pre_bundle.mode.id
              }
            }
          })
        })
      }
      this.$http.get(config.menu.host + '/operator_setting').then(oper => {
        this.$http.get(config.menu.host + '/bundle/global/new/pre_active').then(global_preactive_bundle => {
          var operbasebundle = []
          oper.body[0].bundle.map(function (bundle) {
            operbasebundle.push(bundle.bundle_name)
          })
          var current_date = new Date()
          this.wholebundle = global_preactive_bundle.body
          this.wholebundle.map(function (whole, index) {
            if (opArr.indexOf(whole.bundle_name) == -1) {
              whole.id = index++
              if ((vm.allowed_device == 'mobile+tv+stb')) {
                if (whole.allowed_device == 'stb_app') {
                  vm.opGlobalArr.push(whole)
                }
              }
              if ((vm.allowed_device == 'mobile+tv')) {
                if (whole.allowed_device == 'only_app') {
                  vm.opGlobalArr.push(whole)
                }
              }
              if ((vm.allowed_device == 'stb')) {
                if (whole.allowed_device == 'only_stb') {
                  vm.opGlobalArr.push(whole)
                }
              }
              if (!operbasebundle.includes(whole.bundle_name)) {
                vm.prename.push(whole.bundle_name)
              }
            }
          })
          if (oper.body[0].pre_activation_flag) {
            this.operatorpre = oper.body[0].pre_activation
            if (vm.operatorpre.length > 0) {
              vm.operatorpre.map(function (pre_active, index, id) {
                if (!pre_active.removed_flag) {
                  var pre = pre_active.bundle_name
                  var prebundle = pre.bundle_name
                  if ((pre_active.free_count > pre_active.used_count) && (new Date(pre_active.end_date).getTime() > current_date.getTime()) && (new Date(pre_active.start_date).getTime() <= current_date.getTime())) {
                    if (pre_active.mode.id == vm.mode.id) {
                      vm.pre_subscription = true
                    }
                  }
                }
              })
            }
          }
          this.bundleArrAll.map(function (argument) {
            if ((argument.bundle_mode == mode.id) && (vm.allowed_device == 'mobile+tv+stb')) {
              if (vm.prename.indexOf(argument.bundle_name) == -1) {
                if (argument.allowed_device == 'stb_app') {
                  if (!input_base_arr.some(function (data) {
                    return (data.bundle_name == argument.bundle_name)
                  })) {
                    input_base_arr.push(argument)
                  }
                }
              }
            }
            if ((argument.bundle_mode == mode.id) && (vm.allowed_device == 'mobile+tv')) {
              if (vm.prename.indexOf(argument.bundle_name) == -1) {
                if (argument.allowed_device == 'only_app') {
                  if (!input_base_arr.some(function (data) { return (data.bundle_name == argument.bundle_name) })) {
                    input_base_arr.push(argument)
                  }
                }
              }
            }
            if ((argument.bundle_mode == mode.id) && (vm.allowed_device == 'stb')) {
              if (vm.prename.indexOf(argument.bundle_name) == -1) {
                if (argument.allowed_device == 'only_stb') {
                  if (!input_base_arr.some(function (data) { return (data.bundle_name == argument.bundle_name) })) {
                    input_base_arr.push(argument)
                  }
                }
              }
            }
          })
          input_base_arr.map(function (data, x) { data.id = x + 1 })
          this.bundleArr = input_base_arr
        })
      })

      var input_addon_arr = []
      this.addonbundleArrAll.map(function (argumented) {
        if (vm.isCreate || vm.importModal) {
          if ((argumented.bundle_mode == mode.id) && (vm.allowed_device == 'mobile+tv+stb')) {
            if (argumented.allowed_device == 'stb_app') {
              input_addon_arr.push(argumented)
            }
            if (argumented.allowed_device == 'only_stb') {
              input_addon_arr.push(argumented)
            }
          }
          if ((argumented.bundle_mode == mode.id) && (vm.allowed_device == 'mobile+tv')) {
            if (argumented.allowed_device == 'only_stb') {
              input_addon_arr.push(argumented)
            }
          }
          if ((argumented.bundle_mode == mode.id) && (vm.allowed_device == 'stb')) {
            if (argumented.allowed_device == 'only_stb') {
              input_addon_arr.push(argumented)
            }
          }
        } else if (vm.isUpdate) {
          if (vm.allowed_device == 'mobile+tv+stb') {
            if (argumented.allowed_device == 'stb_app') {
              input_addon_arr.push(argumented)
            }
            if (argumented.allowed_device == 'only_stb') {
              input_addon_arr.push(argumented)
            }
          }
          if (vm.allowed_device == 'mobile+tv') {
            if (argumented.allowed_device == 'only_stb') {
              input_addon_arr.push(argumented)
            }
          }
          if (vm.allowed_device == 'stb') {
            if (argumented.allowed_device == 'only_stb') {
              input_addon_arr.push(argumented)
            }
          }
        }
      })
      this.addonbundleArr = input_addon_arr
      this.addonbundleArr.map(function (data, i) { data.id = i + 1 })
    },
    initiateImport () {
      this.importModal = true
      this.makeImport()
    },
    proceedmanualRenewal (type) {
      if (type == 'YES') {
        if (this.renewal_entity != null) {
          this.renew(this.renewal_entity, true)
        } else {
          this.manualRenewal(true)
        }
      } else {
        if (this.renewal_entity != null) {
          this.renew(this.renewal_entity, false)
        } else {
          this.manualRenewal(false)
        }
      }
    },
    proceedPreRenew (type) {
      if (type == 'YES') {
        this.renewConfirm = true
        this.pre_renew = false
      } else {
        this.list()
      }
    },
    manualRenewal (type) {
      var renewalarr = []
      var bundlearr = []
      this.subscriptionData.map(function (item) {
        if (item.checkbox_value) {
          renewalarr.push(item.subscription_id)
          if (bundlearr.indexOf(item.bundle) < 0) {
            bundlearr.push(item.bundle)
          }
        }
      })
      var domain = location.host
      var re_url = 'https://' + domain
      var payload = {
        id_array: renewalarr,
        redirection_url: re_url,
        renewal_with_addon: type,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/subscription/manualRenewal', payload).then(resp => {
        loader.hide()
        if (resp.body.url) {
          window.open(resp.body.url, '_self')
        } else {
          Vue.notify({ text: resp.body, type: 'success' })
          this.list()
        }
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
          return this.$router.push('transaction').catch(() => {})
        }
      })
    },
    getAllSubscription (page = 0) {
      // this.$http.get(config.menu.host + '/operator_setting').then(oper => {
      // this.operatorpre = oper.body[0].pre_activation
      if (this.term) {
        return this.search(this.term, page)
      }
      if (this.opfilter) {
        return this.getOrgFilter(this.opfilter, page)
      }
      var filt = this.filter ? this.filter.id : 'all'

      this.checkboxFlag = false
      // this.filter = ''
      this.cancelImport()
      this.$http.get(config.menu.host + '/org').then(resp => {
        this.operFilterArr = resp.body.filter(function (thing) {
          return thing.org_type == 'OPERATOR' || thing.org_type == 'HEPI_OPERATOR'
        })
        this.operFilterArr.map(function (arg, i) { arg.id = i + 1 })
      })
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/subscription/' + filt + '/' + this.perPage + '/' + page)
        .then(response => {
          loader.hide()
          let index = 0
          var start = (page - 1).toString()
          let num = (page == 0) ? 1 : Number(start + 1)
          this.totalPages = response.body.count
          this.subscriptionData = response.body.rows.map(function (item) {
            var addon_grid = ''
            var base_grid = ''
            item.subscription_bundles.map(function (argument) {
              if (argument.addon && argument.addon_status && argument.addon_status != 'progress') {
                if (addon_grid == '') {
                  addon_grid = argument.bundle_name
                } else {
                  addon_grid = addon_grid + ', ' + argument.bundle_name
                }
              }
              if (argument.base) {
                base_grid = argument.bundle_name
              }
            })
            if (item.serial_no == 'null') {
              item.serial_no = ''
            }
            if (item.mac_address == 'null') {
              item.mac_address = ''
            }
            item.addon_grid = addon_grid
            item.bundle = base_grid
            item.logoutFlag = !!((item.status == 'Active' && item.accesslogins.length > 0))
            item.id = index++
            item.sno = num++
            item.checkbox_value = false
            return item
          })
          this.isForm = false
          this.isshowForm = false
          this.isshowgrid = true
          if (this.subscriptionData.length == 0) this.isCustPresent = false
          else this.isCustPresent = true
          function QueryStringToJSON () {
            var pairs = location.search.slice(1).split('&')
            var result = {}
            pairs.forEach(function (pair) {
              pair = pair.split('=')
              result[pair[0]] = decodeURIComponent(pair[1] || '')
            })
            return JSON.parse(JSON.stringify(result))
          }
          var query_string = QueryStringToJSON()
          if (Object.keys(query_string).length > 1) {
            var domain = location.host
            var re_url = 'https://' + domain
            this.$http.put(config.menu.host + '/subscription/', query_string).then(response => {
              location = re_url + '/#/admin/subscription'
            })
          }
        })
      // })
    },
    backPage () {
      this.isshowgrid = true
      this.isshowSecond = false
      this.isFullWidth = false
      this.upgradebundle = ''
      this.importData = []
      this.cancelImport()
    },
    cancel () {
      this.showRemoveModal = false
      this.showStatusChange = false
    },

    getpaytv () {
      var base = []
      if (this.upgradebundle && this.upgradebundle.pay_channels && this.upgradebundle.pay_channels.length > 0) {
        base = [...this.upgradebundle.pay_channels]
      } if (!this.upgradebundle) {
        base.push(this.bundle.pay_channel)
      }
      if (this.addonbundle.length > 0) {
        this.addonbundle.forEach(function (element) {
          base.push(element.pay_channel)
        })
      }
      if (base.length > 0) {
        const channels = base.toString().split(',')
        this.columns = this.createColumns(channels, 0)
        this.tabValue = 0
        this.isNewCard = true
      } else {
        this.clearChannels()
      }
      if (this.upgradebundle.pay_channel) {
        const channels = this.upgradebundle.pay_channel.toString().split(',')
        this.columns = this.createColumns(channels, 0)
        this.tabValue = 0
        this.isNewCard = true
      }
    },

    getzee5premium () {
      var base = []
      if (this.upgradebundle && this.upgradebundle.zee_channels && this.upgradebundle.zee_channels.length > 0) {
        base = [...this.upgradebundle.zee_channels]
      } if (!this.upgradebundle) {
        base.push(this.bundle.zee_channel)
      }
      if (this.addonbundle.length > 0) {
        this.addonbundle.forEach(function (element) {
          base.push(element.zee_channel)
        })
      }
      if (base.length > 0) {
        const channels = base.toString().split(',')
        this.columns = this.createColumns(channels, 1)
      } else {
        this.clearChannels()
      }
      if (this.upgradebundle.zee_channel) {
        const channels = this.upgradebundle.zee_channel.toString().split(',')
        this.columns = this.createColumns(channels, 1)
      }
    },

    getfta () {
      var base = []
      if (this.upgradebundle && this.upgradebundle.ftachannels && this.upgradebundle.fta_channels.length > 0) {
        base = [...this.upgradebundle.fta_channels]
      } if (!this.upgradebundle) {
        base.push(this.bundle.fta_channel)
      }
      if (this.addonbundle.length > 0) {
        this.addonbundle.forEach(function (element) {
          base.push(element.fta_channel)
        })
      }
      if (base.length > 0) {
        const channels = base.toString().split(',')
        this.columns = this.createColumns(channels, 2)
      } else {
        this.clearChannels()
      }
      if (this.upgradebundle.fta_channel) {
        const channels = this.upgradebundle.fta_channel.toString().split(',')
        this.columns = this.createColumns(channels, 2)
      }
    },

    clearChannels () {
      this.columns = []
    },

    createColumns (channels, val) {
      const channelCount = channels.length
      const columnsCount = 3
      const columns = []
      const channelsPerColumn = Math.ceil(channelCount / columnsCount)

      if (channelCount == 1) {
        columns.push([channels[0]], [], [])
        return columns
      }

      for (let i = 0; i < columnsCount; i++) {
        const start = i * channelsPerColumn
        const end = start + channelsPerColumn
        columns.push(channels.slice(start, end))
      }
      this.isNewCard = true
      this.tabValue = val
      return columns
    },

    createSubscription (type) {
      this.iscreated = true
      var ref = this
      if (!this.bulkCreate) {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        if (this.allowed_device == '') {
          loader.hide()
          return Vue.notify({ text: 'Please Selected the Allowed Device', type: 'error' })
        }
        if ((this.checkIptv || this.allowed_device == 'mobile+tv+stb' || this.allowed_device == 'stb') && !this.serial_no) {
          loader.hide()
          return Vue.notify({ text: 'Please fill Serial Number', type: 'error' })
        }

        if (this.org_stb_flag && !this.serial_no) {
          loader.hide()
          return Vue.notify({ text: 'Please fill Serial Number', type: 'error' })
        }
        this.nameErrors = this.name ? [] : ['Name is required']
        this.mobileErrors = this.mobile ? [] : ['Mobile Number is required']
        this.modeErrors = this.mode ? [] : ['Mode is required']
        this.bundleErrors = this.bundle ? [] : ['Bundle is required']
        if (!this.formReady) {
          loader.hide()
          return
        }
        if ((this.mobile.length != 10) || !REGEX.Number(this.mobile)) {
          loader.hide()
          return Vue.notify({ text: 'Mobile Number is not valid!', type: 'error' })
        }

        var subscription_id = this.guid()
        this.bundle.subscription_id = subscription_id

        this.addonbundle.map(function (num) {
          delete num.id
          num.subscription_id = subscription_id
        })
        delete this.bundle.id
        delete this.upgradebundle.id
        var subscription_bundles = []
        subscription_bundles.push(this.bundle)
        subscription_bundles = [...subscription_bundles, ...this.addonbundle]
        if (this.bundle.bundle_type === 'custompackage') {
          this.amount = this.bundle.bundle_cost
        }
        this.amt_on_call = this.amount
        if (this.ncf_bundle) {
          // this.amount = this.amount + this.ncf_pay
          this.amount = this.amount
          this.ncf_bundle.addon = true
          this.ncf_bundle.base = false
          this.ncf_bundle.addon_status = 'true'
          var ncf_bundle = [this.ncf_bundle]
          subscription_bundles = [...subscription_bundles, ...ncf_bundle]
        }
        var amt_without_gst = this.amount
        var gst = ((this.amount * 18) / 100)
        var amt_with_gst = amt_without_gst + gst
        if ((this.stbFlag && this.allowed_device == 'mobile+tv+stb') || this.checkIptv) {
          this.stb = true
        } else if (this.org_stb_flag) {
          this.checkIptv = true
        } else {
          this.stb = false
        }
        var payload = {
          subscription_id: subscription_id,
          name: this.name,
          email: this.mobile + '@gmail.com',
          bundle: this.bundle.bundle_name,
          mobile: this.mobile,
          mode: this.mode.id,
          amount: amt_with_gst,
          autorenewal: this.autorenewal,
          bulkCreate: this.bulkCreate,
          add_on: (this.addonbundle.length > 0),
          subscription_bundles: this.upgradebundle ? [this.upgradebundle] : subscription_bundles,
          serial_no: this.serial_no.unique_id,
          mac_address: this.serial_no.mac_address,
          stb_type: this.serial_no.model,
          checkIptv: this.checkIptv,
          stb: this.stb,
          app: this.app,
          renewal_type: this.renewal_type,
          bundle_type: this.bundle_type,
          allowed_device: this.allowed_device,
          get_pre_activation: this.get_pre_activation,
          delta_amount: this.total_payable,
          current_bundle: this.bundle,
          upgrade_bundle: this.upgradebundle,
        }
        if (payload.subscription_bundles.length > 0) {
          payload.subscription_bundles.map(function (data) {
            delete data.pay_channel
            delete data.zee_channel
            delete data.fta_channel
          })
        }
        if (type == 'initial') {
          payload.get_pre_activation = true
        } else if (type == 'pre_sub') {
          payload.get_pre_activation = false
        }
        loader.hide()
        execute(this)
      } else {
        var dupeFlag = false
        var cust_arr = []
        var amount = 0
        var vm = this
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        if (this.cust_list.length > 0) {
          loop:
          for (var i = 0; i < vm.cust_list.length; i++) {
            var factor = vm.cust_list[i]
            if ((vm.checkIptv && (factor.serial_number == '')) || (this.allowed_device == 'mobile+tv+stb' && (factor.serial_number == ''))) {
              loader.hide()
              return Vue.notify({ text: 'Please fill Serial Number', type: 'error' })
            }
            // if (vm.org_stb_flag && !factor.serial_number) {
            //   loader.hide()
            //   return Vue.notify({ text: 'Please select the Serial Number', type: 'error' })
            // }
            factor.mobile = factor.mobile.trim()
            if ((factor.mobile.length != 10) || !REGEX.Number(factor.mobile)) {
              loader.hide()
              return Vue.notify({ text: factor.mobile + ' Mobile Number is not valid!', type: 'error' })
            }
          }
          this.cust_list.map(function (prop) {
            var subscription_id = vm.guid()
            amount = amount + prop.amount
            prop.amount = prop.amount + ((prop.amount * 18) / 100)
            if (vm.checkIptv || vm.allowed_device == 'mobile+tv+stb') {
              if (cust_arr.some(function (item) { return ((item.serial_no == prop.serial_number.unique_id)) })) {
                vm.isShowCust = false
                amount = 0
                dupeFlag = true
                prop.amount = vm.amount
                prop.flag = true
                vm.isShowCust = true
              } else {
                delete prop.flag
                prop.subscription_id = subscription_id
                prop.mac_address = prop.serial_number.mac_address
                prop.stb_type = prop.serial_number.model
                prop.serial_no = prop.serial_number.unique_id
                prop.autorenewal = vm.autorenewal
                prop.renewal_type = vm.renewal_type
                cust_arr.push(prop)
              }
            } else {
              // if (cust_arr.some(function (item) { return ((item.email == prop.email) || (item.mobile == prop.mobile)) })) {
              //   vm.isShowCust = false
              //   amount = 0
              //   dupeFlag = true
              //   prop.amount = vm.amount
              //   prop.flag = true
              //   vm.isShowCust = true
              // } else {
              delete prop.flag
              prop.subscription_id = subscription_id
              prop.autorenewal = vm.autorenewal
              prop.renewal_type = vm.renewal_type
              cust_arr.push(prop)
              // }
            }
          })
        }
        var subscription_bundles = []
        delete this.bundle.id
        subscription_bundles.push(this.bundle)
        subscription_bundles = [...subscription_bundles, ...this.addonbundle]
        if (this.ncf_bundle) {
          this.ncf_bundle.addon = true
          this.ncf_bundle.base = false
          this.ncf_bundle.addon_status = 'true'
          var ncf_bundle = [this.ncf_bundle]
          subscription_bundles = [...subscription_bundles, ...ncf_bundle]
        }
        var amt_without_gst = amount
        var gst = ((amt_without_gst * 18) / 100)
        var amt_with_gst = amt_without_gst + gst
        if (this.bundle.bundle_type === 'custompackage') {
          this.bundle_type = 'custompackage'
          amount = 0
          amount = this.bundle.bundle_cost * cust_arr.length
        }
        var payload = {
          amount: amount,
          mode: this.mode.id,
          bundle: cust_arr[0].bundle,
          bulkCreate: this.bulkCreate,
          arr: cust_arr,
          add_on: (this.addonbundle.length > 0),
          subscription_bundles: subscription_bundles,
          checkIptv: vm.checkIptv,
          bundle_type: this.bundle_type,
        }
        if (!dupeFlag) {
          loader.hide()
          execute(this)
        } else {
          this.cust_list.map(function (arg) { arg.amount = vm.amount })
          Vue.notify({ text: 'Please remove duplicate entries', type: 'error' })
          return loader.hide()
        }
      }
      function execute (vm) {
        vm.addonbundleArr.map(function (iter, i) { iter.id = i + 1 })
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        ref.$http.post(config.menu.host + '/subscription', payload).then(resp => {
          loader.hide()
          if (resp && resp.body.msg) {
            ref.msgFlag = true
            ref.msg = resp.body.msg
            ref.btn = resp.body.btn
            ref.account_balance = resp.body.account_balance
            ref.adjusted_amount = resp.body.adjusted_amount
            ref.reseller_cost = resp.body.reseller_cost
            ref.pre_set_flag = resp.body.pre_set_flag
            ref.pre_active_bundle = resp.body.pre_active_bundle
          } else if (resp && resp.body.url) {
            window.open(resp.body.url, '_self')
          } else {
            Vue.notify({ text: resp.body, type: 'success' })
            ref.list()
          }
        }, errResponse => {
          loader.hide()
          if (errResponse && errResponse.body.dupe) {
            var totalData = ref.cust_list
            ref.isShowCust = false
            totalData.map(function (obj) {
              errResponse.body.dupe.map(function (thing) {
                if (obj.name == thing.name) {
                  obj.amount = ref.amount
                  obj.flag = true
                }
              })
            })
            ref.cust_list = totalData
            ref.isShowCust = true
            Vue.notify({ text: 'Please change duplicate details', type: 'error' })
          } else {
            Vue.notify({ text: errResponse.body, type: 'error' })
          }
        })
      }
    },
    alreadycreated () {
      this.msgFlag = false
    },
    saveImport () {
      if (this.importData && this.importData.length > 0) {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        var file_content = this.importData[0]
        var reference = this.$http
        var ref = this
        var bundle = this.bundle
        var mode = this.mode
        var sno = 0
        var str = file_content.type.split('/')
        if (!bundle) {
          loader.hide()
          return Vue.notify({ text: 'Please Select Bundle', type: 'error' })
        }
        if (!mode) {
          loader.hide()
          return Vue.notify({ text: 'Please Select Mode', type: 'error' })
        }
        if (this.allowed_device == '') {
          loader.hide()
          return Vue.notify({ text: 'Please Select Allowed Device', type: 'error' })
        }
        this.selectedFile = file_content
        if (typeof (FileReader) !== 'undefined') {
          var reader = new FileReader()
          reader.onload = function (e) {
            var headerKeys = ['Name', 'Mobile']
            var cust_list = new Array()
            var rows = e.target.result.split('\n')
            var proceedFlag = false
            for (var i = 0; i < rows.length; i++) {
              if (i == 0) {
                var cells = rows[i].split(',')
                var compareArray = JSON.stringify(headerKeys) == JSON.stringify(cells).replace(/\\r/g, '')
                if (compareArray) {
                  proceedFlag = true
                } else {
                  loader.hide()
                  return Vue.notify({ text: "Please don't remove or modify the columns", type: 'error' })
                }
              }
              if (proceedFlag && (i > 0)) {
                var cells = rows[i].split(',')
                if (cells.length > 1) {
                  if ((cells[0] && cells[0].length > 0) && (cells[1] && cells[1].length > 0)) {
                    var cust = {}
                    cust.index = sno++
                    cust.name = cells[0]
                    // cust.email = cells[1]
                    cust.mobile = cells[1]
                    cust.email = cells[1] + '@gmail.com'
                    cust.bundle = bundle.bundle_name
                    cust.mode = mode.id
                    cust.amount = ref.amount
                    cust.addonbundle = ref.addonbundle
                    cust.allowed_device = ref.allowed_device
                    cust.serial_number = ''
                    cust.stb = ref.stb
                    cust.app = ref.app
                    cust_list.push(cust)
                  } else {
                    cust_list = []
                    loader.hide()
                    return Vue.notify({ text: "Please enter valid details, don't leave it empty", type: 'error' })
                  }
                }
              }
            }
            if (cust_list.length > 0) {
              loader.hide()
              ref.isShowCust = true
              ref.importModal = false
              ref.isshowgrid = false
              ref.bulkCreate = true
              ref.cust_list = cust_list
            } else {
              loader.hide()
              return Vue.notify({ text: 'File is empty.', type: 'error' })
            }
          }
          reader.readAsText(this.selectedFile)
        } else {
          loader.hide()
          return alert('This browser does not support HTML5.')
        }
      } else {
        return Vue.notify({ text: 'No File Found.', type: 'error' })
      }
    },
    adjustablePay () {
      var ref = this
      var domain = location.host
      var re_url = 'https://' + domain
      if (!this.bulkCreate) {
        if ((this.mobile.length != 10) || !REGEX.Number(this.mobile)) {
          return Vue.notify({ text: 'Mobile Number is not valid!', type: 'error' })
        }
        var subscription_id = this.guid()
        this.bundle.subscription_id = subscription_id
        this.addonbundle.map(function (num) {
          delete num.id
          num.subscription_id = subscription_id
        })
        delete this.bundle.id
        delete this.upgradebundle.id
        var subscription_bundles = []
        subscription_bundles.push(this.bundle)
        subscription_bundles = [...subscription_bundles, ...this.addonbundle]
        if (this.ncf_bundle) {
          this.ncf_bundle.addon = true
          this.ncf_bundle.base = false
          this.ncf_bundle.addon_status = 'progress'
          var ncf_bundle = [this.ncf_bundle]
          subscription_bundles = [...subscription_bundles, ...ncf_bundle]
        }
        var amt_without_gst = this.amount
        var gst = ((amt_without_gst * 18) / 100)
        var amt_with_gst = amt_without_gst + gst
        var payload = {
          name: this.name,
          email: this.mobile + '@gmail.com',
          redirection_url: re_url,
          bundle: this.bundle.bundle_name,
          mobile: this.mobile,
          mode: this.mode.id,
          amount: amt_with_gst,
          rate: this.amt_on_call,
          quantity: 1,
          account_balance: Number(this.account_balance),
          adjusted_amount: this.adjusted_amount,
          reseller_cost: this.reseller_cost,
          autorenewal: this.autorenewal,
          bulkCreate: this.bulkCreate,
          add_on: (this.addonbundle.length > 0),
          subscription_bundles: this.upgradebundle ? [this.upgradebundle] : subscription_bundles,
          serial_no: this.serial_no.unique_id,
          mac_address: this.serial_no.mac_address,
          stb_type: this.serial_no.model,
          checkIptv: this.checkIptv,
          app: this.app,
          stb: this.stb,
          bundle_type: this.bundle_type,
          bundle_cost: this.bundle_cost,
          renewal_type: this.renewal_type,
          subscriber_flag: this.subscriber_flag,
          allowed_device: this.allowed_device,
          pre_set_flag: this.pre_set_flag,
          pre_active_bundle: this.pre_active_bundle,

          // extended_external_app: this.extended_external_app
        }
        payable()
      } else {
        var cust_arr = []
        var amount = 0
        var vm = this
        if (this.cust_list.length > 0) {
          this.cust_list.map(function (prop) {
            amount = amount + prop.amount
            if (cust_arr.some(function (item) { return ((item.email == prop.email) || (item.mobile == prop.mobile)) })) {
              cust_arr = []
              return Vue.notify({ text: 'Please remove duplicate entries.', type: 'error' })
            } else {
              if (prop.serial_no) {
                prop.serial_no = prop.serial_no.unique_id ? prop.serial_no.unique_id : prop.serial_no
                prop.mac_address = prop.mac_address
                prop.stb_type = prop.model
              }
              prop.checkIptv = vm.checkIptv
              prop.renewal_type = vm.renewal_type
              cust_arr.push(prop)
            }
          })
        }
        var subscription_bundles = []
        subscription_bundles.push(this.bundle)
        subscription_bundles = [...subscription_bundles, ...this.addonbundle]
        this.addonFlag = false
        this.addonFlag = (this.addonbundle.length > 0)
        if (this.ncf_bundle) {
          this.addonFlag = true
          this.ncf_bundle.addon = true
          this.ncf_bundle.base = false
          this.ncf_bundle.addon_status = 'progress'
          var ncf_bundle = [this.ncf_bundle]
          subscription_bundles = [...subscription_bundles, ...ncf_bundle]
        }
        var rateData = this.keyObj[this.mode.id]
        var amt_without_gst = ((this.bundle[rateData]) * (cust_arr.length))
        var gst = ((amt_without_gst * 18) / 100)
        var payload = {
          amount: amount,
          mode: this.mode.id,
          redirection_url: re_url,
          account_balance: this.account_balance,
          adjusted_amount: this.adjusted_amount,
          bundle: cust_arr[0].bundle,
          bulkCreate: this.bulkCreate,
          rate: this.bundle[rateData],
          quantity: cust_arr.length,
          arr: cust_arr,
          add_on: this.addonFlag,
          subscription_bundles: subscription_bundles,
          stb: this.stb,
          app: this.app,
          bundle_type: this.bundle_type,
          bundle_cost: this.bundle_cost,
        }
        payable()
      }
      function payable () {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        ref.$http.post(config.menu.host + '/subscription/adjustablePay', payload).then(resp => {
          loader.hide()
          if (resp && resp.body.url) {
            window.open(resp.body.url, '_self')
          } else {
            Vue.notify({ text: resp.body, type: 'success' })
            this.list()
          }
        }, errResponse => {
          loader.hide()
          if (errResponse && errResponse.body.dupe) {
            var totalData = ref.cust_list
            ref.isShowCust = false
            totalData.map(function (obj) {
              errResponse.body.dupe.map(function (thing) {
                if (obj.name == thing.name) {
                  obj.flag = true
                }
              })
            })
            ref.cust_list = totalData
            ref.isShowCust = true
            Vue.notify({ text: 'Please change duplicate details', type: 'error' })
          } else {
            Vue.notify({ text: errResponse.body, type: 'error' })
          }
        })
      }
    },
    updateProcess () {
      var delarr = []
      var samearr = []
      var newarr = []
      var reupdatearr = []
      var domain = location.host
      var re_url = 'https://' + domain
      var vm = this

      var parse = JSON.parse(vm.existing)
      if (parse.length > 0) {
        this.finish('null')
        parse.map(function (ele) {
          if (vm.addonbundle.length > 0) {
            vm.addonbundle.map(function (arg) {
              var flag = false
              if ((arg.bundle_id == ele.bundle_id) && (ele.addon_status == true)) {
                samearr.push(ele.bundle_id)
              }
              if (arg.addon_status == 'progress') {
                vm.cancelArr.map(function (cal) {
                  if (cal.bundle_id == arg.bundle_id) {
                    var inside = reupdatearr.indexOf(arg.bundle_id)
                    if (inside < 0) { reupdatearr.push(arg.bundle_id) }
                  }
                  var pos = newarr.map(function (e) { return e.bundle_id }).indexOf(arg.bundle_id)
                  var position = parse.map(function (c) { return c.bundle_id }).indexOf(arg.bundle_id)
                  var point = reupdatearr.map(function (d) { return d.bundle_id }).indexOf(arg.bundle_id)
                  var entry = samearr.indexOf(arg.bundle_id)
                  arg.subscription_id = vm.subscription_id
                  delete arg.id
                  if ((position < 0) && (pos < 0) && (point < 0) && (entry < 0)) {
                    newarr.push(arg)
                  }
                })
              }
              if ((ele.addon_status == 'true') || (ele.addon_status == true)) {
                var pos = delarr.indexOf(ele.bundle_id)
                var entry = samearr.indexOf(ele.bundle_id)
                if ((pos < 0) && (entry < 0)) {
                  delarr.push(ele.bundle_id)
                }
              }
              if (vm.cancelArr.length > 0 && reupdatearr.length == 0) {
                var pos = samearr.indexOf(arg.bundle_id)
                var thing = newarr.map(function (e) { return e.bundle_id }).indexOf(arg.bundle_id)
                if ((pos < 0) && (thing < 0)) {
                  delete arg.id
                  newarr.push(arg)
                }
              }
              if (vm.cancelArr.length == 0 && arg.addon_status == 'progress') {
                var pos = delarr.indexOf(arg.bundle_id)
                var entry = samearr.indexOf(arg.bundle_id)
                var thing = newarr.map(function (e) { return e.bundle_id }).indexOf(arg.bundle_id)
                if ((pos < 0) && (entry < 0) && (thing < 0)) {
                  delete arg.id
                  newarr.push(arg)
                }
              }
            })
          } else {
            if (JSON.parse(vm.existing).length > 0) {
              delarr.push(ele.bundle_id)
            }
          }
        })
      } else {
        newarr = vm.addonbundle
      }
      var newlyarr = []
      var serial_mac = this.serialAndMac.split('-')
      var serial = serial_mac[0].trim(); var mac_data = serial_mac[1].trim()
      newarr.map(function (argument) {
        delete argument.pay_channel
        delete argument.zee_channel
        delete argument.fta_channel
        var same = samearr.indexOf(argument.bundle_id)
        var del = delarr.indexOf(argument.bundle_id)
        var reupdate = reupdatearr.indexOf(argument.bundle_id)
        if ((same < 0) && (del < 0) && (reupdate < 0)) {
          newlyarr.push(argument)
        }
      })
      delarr = delarr.filter(function (iter) {
        var same = samearr.indexOf(iter)
        return (same < 0)
      })
      if (this.subscriber_flag) {
        this.base_bundle_updation = true
      }
      if (this.base_bundle_updation) {
        var days = (this.valueObj[this.mode.id]) || (this.valueObj[this.mode])
        var expiry = new Date()
        var dateExpiry = new Date(expiry.setDate(expiry.getDate() + days))
        this.expirtDate = new Date(dateExpiry).getFullYear() + '-' + (new Date(dateExpiry).getMonth() + 1) + '-' + new Date(dateExpiry).getDate()
      } else {
        this.expirtDate = this.expires_on
      }
      if (this.bundle) {
        delete this.bundle.pay_channel
        delete this.bundle.zee_channel
        delete this.bundle.fta_channel
      }
      var payload = {
        subscription_id: this.subscription_id,
        name: this.name,
        email: this.mobile + '@gmail.com',
        bundle: this.bundle,
        mobile: this.mobile,
        mode: this.mode.id ? this.mode.id : this.mode,
        redirection_url: re_url,
        status: this.status.id,
        autorenewal: this.autorenewal,
        expires_on: this.expirtDate,
        delarr: delarr,
        samearr: samearr,
        newarr: newlyarr,
        reupdatearr: reupdatearr,
        serial_no: this.serial_no.unique_id ? this.serial_no.unique_id : serial,
        mac_address: this.serial_no.mac_address ? this.serial_no.mac_address : mac_data,
        stb_type: this.serial_no.model,
        checkIptv: this.checkIptv,
        phone_number: this.mobile,
        stb: (!this.checkIptv) ? false : this.stb,
        app: (!this.checkIptv) ? false : this.app,
        base_bundle_updation: this.base_bundle_updation,
        exist_base_bundleid: this.exist_base_bundleid,
        update_and_renew: this.subscriber_flag,
        renewal_type: this.renewal_type,
        status: this.status,
        allowed_device: this.allowed_device,
        extended_subscription: this.extended_subscription,
      }
      payload.ncf_already_added = this.ncf_already_added
      if ((!this.already_checkiptv) && this.checkIptv) {
        payload.ncf_already_added = false
      } else {
        payload.ncf_already_added = true
      }
      payload.is_iptv = this.checkIptv
      if (this.ncf_bundle) {
        payload.ncf_bundle = this.ncf_bundle
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/subscription/' + this.subscription_id, payload).then(resp => {
        loader.hide()
        var ref = this
        if (resp && resp.body.msg) {
          ref.msgFlag = true
          ref.msg = resp.body.msg
          ref.btn = resp.body.btn
          ref.account_balance = resp.body.account_balance
          ref.adjusted_amount = resp.body.adjusted_amount
          ref.newarr_on_edit = resp.body.newarr
          ref.samearr_on_edit = resp.body.samearr
          ref.reupdatearr_on_edit = resp.body.reupdatearr
          ref.delarr_on_edit = resp.body.delarr
          ref.no_of_days = resp.body.no_of_days
          ref.app = resp.body.app
          ref.base_bundle_updation = resp.body.base_bundle_updation
          ref.updated_base_bundle = resp.body.updated_base_bundle
          ref.exist_base_bundleid = resp.body.exist_base_bundleid
          ref.reseller_cost = resp.body.reseller_cost
          // ref.extended_external_app = resp.body.extended_external_app
          this.addonbundleArr.map(function (arg, i) {
            arg.id = i + 1
          })
        } else if (resp && resp.body.url) {
          window.open(resp.body.url, '_self')
        } else if (resp && resp.body) {
          Vue.notify({ text: resp.body, type: 'success' })
          this.list()
        }
      }, err => {
        loader.hide()
        if (err && err.body) {
          Vue.notify({ text: err.body, type: 'error' })
        }
      })
    },
    proceedSub () {
      this.extended_subscription = true
      this.updateProcess()
    },
    unproceedSub () {
      this.extended_subscription = false
      this.updateProcess()
    },
    updateSubscription () {
      this.nameErrors = this.name ? [] : ['Name is required']
      this.mobileErrors = this.mobile ? [] : ['Mobile Number is required']
      this.modeErrors = this.mode ? [] : ['Mode is required']
      this.bundleErrors = this.bundle ? [] : ['Bundle is required']
      if (!this.formReady) {
        loader.hide()
        return
      }
      if ((this.mobile.length != 10) || !REGEX.Number(this.mobile)) {
        return Vue.notify({ text: 'Mobile Number is not valid!', type: 'error' })
      }
      var days = (this.valueObj[this.mode.id]) || (this.valueObj[this.mode])
      var expiry = new Date()
      var dateExpiry = new Date(expiry.setDate(expiry.getDate() + days))
      this.changeExpiryDate = new Date(dateExpiry).getFullYear() + '-' + (new Date(dateExpiry).getMonth() + 1) + '-' + new Date(dateExpiry).getDate()
      if (this.serialAndMac == 'null - null') {
        if (this.checkIptv && this.serial_no == '') {
          return Vue.notify({ text: 'Please fill Serial Number', type: 'error' })
        }
      }
      if (this.mode == '') {
        return Vue.notify({ text: 'Please Select Mode', type: 'error' })
      }
      if (this.bundle == '') {
        return Vue.notify({ text: 'Please Select Bundle', type: 'error' })
      }
      this.change_msg = '! You have an active subscription with validity till ' + this.expiry + '. If you decide to renew or change the package now you would lose the unused balance of your existing subscription. Your new subscription will be valid till ' + this.changeExpiryDate + ' . Do you want to continue?'
      if ((this.base_bundle_updation && !this.already_updated)) {
        this.showValidity = true
      } else if ((this.base_bundle_updation && this.already_updated)) {
        this.showValidity = false
      } else if (this.base_bundle_updation) {
        this.showValidity = true
      }
      this.already_updated = true
      if (!this.showValidity) {
        this.updateProcess()
      }
    },
    remove (entity, flag) {
      if (flag == 'delete') {
        this.showRemoveModal = true
        this.commonRemove = 'REMOVE SUBSCRIPTION'
        this.message = 'Are you sure to delete the subscription ' + entity.name + ' ?'
        this.entity = entity
        this.flag = flag
      }
      if (flag == 'logout') {
        this.showRemoveModal = true
        this.commonRemove = 'LOGOUT SUBSCRIPTION'
        this.message = 'Are you sure to logout the subscription ' + entity.name + ' ?'
        this.entity = entity
        this.flag = flag
      }
    },
    statusChange (val, flag) {
      this.showStatusChange = true
      this.statusRemove = (flag == 'deactivate') ? 'DISABLE SUBSCRIPTION' : 'ENABLE SUBSCRIPTION'
      this.statusMessage = (flag == 'deactivate') ? 'Are you sure to deactivate the subscription ?' : 'Are you sure to activate the subscription ?'
      this.is_active_flag = flag
      this.val = val
    },
    deleteSubscription (entity, flag) {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.delete(config.menu.host + '/subscription/' + entity.subscription_id + '/' + flag).then(resp => {
        loader.hide()
        if (resp && resp.body) {
          Vue.notify({ text: resp.body, type: 'success' })
        }
        this.list()
      }, err => {
        loader.hide()
        if (err && err.body) {
          Vue.notify({ text: err.body, type: 'error' })
        }
      })
    },
    statusChangeSubscription (val, flag) {
      var payload = {
        subscription_id: val.subscription_id,
        status: this.is_active_flag,
        org_id: val.org_id
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/partner/status/change', payload).then(resp => {
        loader.hide()
        Vue.notify({ text: resp.body, type: 'success' })
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    delCustList (row, index) {
      var Amount = this.amount
      this.cust_list = this.cust_list.filter(function (prop) {
        prop.amount = Amount
        return prop.index != row.index
      })
      this.msgFlag = false
    },

    adjustPayEdit () {
      var serial_mac = this.serialAndMac.split('-')
      var serial = serial_mac[0].trim()
      var mac_data = serial_mac[1].trim()
      var domain = location.host
      var re_url = 'https://' + domain
      var payload = {
        name: this.name,
        email: this.mobile + '@gmail.com',
        mobile: this.mobile,
        mode: this.mode.id ? this.mode.id : this.mode,
        redirection_url: re_url,
        status: this.status.id,
        autorenewal: this.autorenewal,
        expires_on: this.expires_on,
        newarr_on_edit: this.newarr_on_edit,
        samearr_on_edit: this.samearr_on_edit,
        reupdatearr_on_edit: this.reupdatearr_on_edit,
        reseller_cost: this.reseller_cost,
        delarr_on_edit: this.delarr_on_edit,
        serial_no: (typeof (this.serial_no) === 'object') ? this.serial_no.unique_id : serial,
        mac_address: (this.MAC) ? this.MAC : mac_data,
        stb_type: this.serial_no.model,
        checkIptv: this.checkIptv,
        subscription_id: this.subscription_id,
        account_balance: this.account_balance,
        adjusted_amount: this.adjusted_amount,
        adjust_update: true,
        no_of_days: this.no_of_days,
        stb: this.stb,
        app: this.app,
        base_bundle_updation: this.base_bundle_updation,
        updated_base_bundle: this.updated_base_bundle,
        exist_base_bundleid: this.exist_base_bundleid,
        update_and_renew: this.subscriber_flag,
        renewal_type: this.renewal_type,
        allowed_device: this.allowed_device,
        extended_subscription: this.extended_subscription,
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/subscription/adjustablePay', payload).then(resp => {
        loader.hide()
        if (resp && resp.body.url) {
          window.open(resp.body.url, '_self')
        } else {
          Vue.notify({ text: resp.body, type: 'success' })
          this.list()
        }
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    add () {
      this.title = 'Create subscription'
      this.name = ''
      this.email = ''
      this.mobile = ''
      this.bundle = ''
      this.serial_no = ''
      this.mode = ''
      if (this.mode == '') {
        this.bundleArr = []
        this.addonbundleArr = []
        this.opPreactiveArr = []
      }
      this.stb = true
      this.app = false
      this.autorenewal = true
      this.nameErrors = []
      this.serialNoErrors = []
      this.mobileErrors = []
      this.bundleErrors = []
      this.modeErrors = []
      this.cancelArr = []
      this.razorpay = false
      this.isshowForm = true
      this.isForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
      this.renewal_type = 'no_auto_renew'
      this.allowed_device = ''
      this.stbFlag = false
      this.isshowSecond = false
      this.isFullWidth = false
      this.addonbundlefields.push({ bundle_name: '', start_date: this.today(), expiry_date: '' })
      this.no_iptv_change_flag = false
    },
    checkexternalapp () {
      // if (this.external_apps_length > 0) {
      //   this.validityConfirmation = true
      // } else {
      //   this.validityConfirmation = false
      this.updateProcess()
      // }
    },
    edit (row) {
      this.importModal = false
      this.no_iptv_change_flag = false
      this.addonbundlefields.push({ bundle_name: '', start_date: this.today(), expiry_date: '' })
      if (!row.checkIptv && row.allowed_device == 'mobile+tv') {
        this.serialNoFlag = false
      }
      this.showValidity = false
      this.base_bundle_updation = false
      this.already_updated = false
      this.$http.get(config.menu.host + '/bundle/ncf/bundle').then(resp => {
        this.ncf_bundle = resp.body
        if (this.ncf_bundle) {
          var key = this.keyObj[row.mode]
          this.ncf_bundle.key
          this.ncf_pay = this.ncf_bundle[key]
        }
      })
      // this.$http.get(config.menu.host + '/external_app/' + row.subscription_id).then(response => {
      //   this.external_apps_length = response.body.length
      // })
      if (row.status == 'Deactive') {
        this.subscriber_flag = true
      } else {
        this.subscriber_flag = false
      }
      this.already_checkiptv = row.checkIptv
      this.stbFlag = !!(this.already_checkiptv || row.stb)
      this.ncf_already_added = !!(row.serial_no)
      this.expiry = row.expires_on.split('T')[0]
      this.title = 'Update subscription'
      this.subscription_id = row.subscription_id
      this.name = row.name
      this.email = row.email
      this.mobile = row.mobile
      this.bundle = row.bundle
      this.serialAndMac = row.serial_no + ' - ' + row.mac_address
      this.serial_mac_flag = (this.serialAndMac != 'null - null')
      this.MAC = row.mac_address ? row.mac_address : ''
      this.serial_no = row.serial_no ? row.serial_no : ''
      this.mode = row.mode
      this.backupMode = row.mode
      this.status = row.status
      this.autorenewal = row.autorenewal
      this.renewal_type = row.renewal_type
      this.expires_on = row.expires_on
      this.stb = row.stb
      this.app = row.app
      var arr = []; var arr_new = []
      var vm = this
      this.cancelArr = []
      // this.addonbundleArrAll=[]
      this.sub_bundle = row.subscription_bundles
      row.subscription_bundles.map(function (item) {
        vm.addonbundleArrAll.map(function (entity) {
          if (entity.bundle_id == item.bundle_id) {
            entity.id = item.id
            if ((item.addon_status == 'true') || (item.addon_status == '1') || (item.addon_status == 1)) {
              entity.addon_status = true
              entity.start_date = (item.start_date) ? item.start_date : row.activated_on
              entity.expiry_date = (item.expiry_date) ? item.expiry_date : vm.expiry
              arr.push(entity)
            } else if (item.addon_status == 'cancel') {
              var d = new Date()
              entity.addon_status = 'cancel'
              entity.expiry_date = d.toLocaleDateString()
              vm.cancelArr.push(entity)
            }
          }
        })
        if (item.base) {
          var filter = vm.bundleArrAll.filter(function (prop) {
            return (prop.bundle_id == item.bundle_id)
          })
          if (filter.length == 0) {
            var filter = vm.grouped_bundle.filter(function (prop) {
              return (prop.bundle_id == item.bundle_id)
            })
          }
          if (filter.length > 0) {
            vm.bundle = filter[0]
            vm.exist_base_bundleid = filter[0].bundle_id
          }
        }
        // }
      })
      var merged = []
      var back_add = []
      merged = [...arr, ...vm.cancelArr]
      arr.map(function (data) {
        data.getFlag = true
        vm.addonbundle.push(data)
        back_add.push(data)
      })
      if (back_add.length > 0) this.backup_addonbundlefields = JSON.stringify(back_add)
      this.addonbundlefields = this.addonbundle
      this.existing = JSON.stringify(merged)
      this.allowed_device = row.allowed_device
      var input_base_arr = []
      this.bundleArrAll.map(function (argument) {
        if ((argument.bundle_mode == row.mode) && (vm.allowed_device == 'mobile+tv+stb')) {
          if (argument.allowed_device == 'stb_app') {
            input_base_arr.push(argument)
          }
        }
        if ((argument.bundle_mode == row.mode) && (vm.allowed_device == 'mobile+tv')) {
          if (argument.allowed_device == 'only_app') {
            input_base_arr.push(argument)
          }
        }
        if ((argument.bundle_mode == row.mode) && (vm.allowed_device == 'stb')) {
          if (argument.allowed_device == 'only_stb') {
            input_base_arr.push(argument)
          }
        }
      })
      this.bundleArr = input_base_arr
      var input_addon_arr = []
      this.addonbundleArrAll.map(function (argumented) {
        if (vm.allowed_device == 'mobile+tv+stb') {
          if (argumented.allowed_device == 'stb_app') {
            input_addon_arr.push(argumented)
          }
          if (argumented.allowed_device == 'only_stb') {
            input_addon_arr.push(argumented)
          }
        }
        if ((argumented.bundle_mode == row.mode) && (vm.allowed_device == 'mobile+tv')) {
          if (argumented.allowed_device == 'only_app') {
            input_addon_arr.push(argumented)
          }
        }
        if (vm.allowed_device == 'stb') {
          if (argumented.allowed_device == 'only_stb') {
            input_addon_arr.push(argumented)
          }
        }
      })
      this.addonbundleArr = input_addon_arr
      this.backup_addonbundleArr = input_addon_arr
      this.isshowgrid = false
      this.isshowForm = true
      this.isForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    list () {
      if (this.tabValue == 0) {
        this.isshowgrid = true
        this.isshowForm = false
        this.showModal = false
        this.getpaytv()
      }
      if (this.tabValue == 1) {
        this.isshowgrid = true
        this.isshowForm = false
        this.showModal = false
        this.getzee5premium()
      }
      if (this.tabValue == 2) {
        this.isshowgrid = true
        this.isshowForm = false
        this.showModal = false
        this.getfta()
      }
      this.isshowForm = false
      this.isshowSecond = false
      this.isFullWidth = false
      this.upgradebundle = ''
      // this.isUpgrade = true
      this.isForm = false
      this.isshowgrid = true
      this.filter = ''
      this.opfilter = ''
      this.term = ''
      this.isCustPresent = false
      this.checkboxFlag = false
      this.base_bundle_updation = false
      this.isAddon = false
      this.pre_flag = false
      this.pre_subscription = false
      this.getAllSubscription()
    },
    search: debounce(function (term, page = 0) {
      this.term = term
      this.filter = ''
      this.opfilter = ''
      if (term) {
        var payload = {
          term: term,
          limit: this.perPage,
          offset: page,
        }
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.post(config.menu.host + '/subscription/search', payload).then(response => {
          loader.hide()
          let index = 0
          var start = (page - 1).toString()
          let num = (page == 0) ? 1 : Number(start + 1)
          this.totalPages = response.body.count
          this.subscriptionData = response.body.rows.map(function (item) {
            var addon_grid = ''
            item.subscription_bundles.map(function (argument) {
              if (argument.addon) {
                if (addon_grid == '') {
                  addon_grid = argument.bundle_name
                } else {
                  addon_grid = addon_grid + ', ' + argument.bundle_name
                }
              }
            })
            item.addon_grid = addon_grid
            item.logoutFlag = !!((item.status === 'Active' && item.accesslogins.length > 0))
            item.id = index++
            item.sno = num++
            item.checkbox_value = false
            return item
          })
          this.isshowForm = false
          this.isForm = false
          this.isshowgrid = true
          if (this.subscriptionData.length == 0) this.isCustPresent = false
          else this.isCustPresent = true
        })
      } else {
        this.getAllSubscription()
      }
    }, 400),
  },
}
</script>

<style>

.date_picker_label {
  color: #2c82e0;
  font-size: 0.7rem;
  margin-left: 10px;
  font-weight: bold;
}

.width {
  width: 150px;
}

.container1 {
  display: flex;
  background-color: white;
  flex-direction: row;
  min-width: 0;
  width: 100%;
  height: 100%;
}

.page1 {
  width: 50%;
  flex: 1;
}

#page2 {
  float: left;
  display: flex;
  flex: 1;
  min-width: 0;
  flex-direction: column;
  flex-basis: 50px;
  width: 40%;
  min-height: 652px;
  margin: 10px 50px;
}

.tab {
  display: flex;
}

.tabname {
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  grid-gap: 0;
  overflow: auto;
  height: 180px;
  font-size: small;
  margin-left: 10px;
  margin-top: 10px;
}

.active-tab {
  color: #efba18;
}

.column1 {
  display: flex;
  flex-direction: column;
  height: auto;
  padding-right: 10px;
}

#top {
  height: 290px;
  background: linear-gradient(to bottom, #f2f3f4, #ffffff);
  border-radius: 17px;
  margin-bottom: 20px;
}

.head {
  background-color: white;
  font-size: 20px/24px;
  width: fit-content;
  min-width: 190px;
  color: black;
  margin-top: 19px;
  margin-bottom: 5px;
  padding: 5px 15px 5px 15px;
  text-align: center;
  font-weight: bold;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.demo {
  display: flex;
  zoom: 0.8;
}

.per {
  display: flex;
  justify-content: space-around;
  background-color: #f4f4f4;
  margin-top: 15px;
}

pre {
  font-size: 12px;
  font-family: sans-serif;
}

#bottom {
  background-color: #fcf8ec;
  border: 3px dashed #ebd69c;
  border-radius: 15px;
  padding: 7px 0 0 0;
  box-shadow: none;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.card-header-title {
  font-size: 15px;
  font-weight: bold;
  background-color: white;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 130px;
  margin-left: 0;
  text-align: center;
  padding: 6px;
  margin-top: 5px;
}

.card-header-amount {
  font-size: 15px;
  font-weight: bold;
  margin-right: 24px;
  margin-top: 10px;
}

.card-item {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.card-item-title {
  font-size: 15px;
}

.card-item-amount {
  font-size: 16px;
  margin-top: 6px;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
  padding: 0 20px;
}

.full-width {
  width: 50%;
  height: 652px;
}

.card-footer-title {
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
}

.card-footer-amount {
  font-size: 15px;
  font-weight: bold;
  margin-right: 25px;
}

.card-item-container {
  border-top: 2px solid lightgrey;
  border-bottom: 2px dashed lightgrey;
  padding: 7px;
  margin: 0 25px;
  max-height: 140px;
  min-height: 50px;
  overflow: auto;
}

.labelwidth {
  width: 149px;
  margin-top: 17px;
}

.left {
  margin-left: 6px;
  color: black;
}

.word {
  word-wrap: break-word;
}

.activeClass {
  background-color: #ff8585;
  color: black;
}

.download_script:hover {
  text-decoration: underline;
}

.fields {
  display: grid;
  grid-template-columns: 6fr 6fr 6fr 6fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.va-modal__title {
  color: #000000;
}

.black--text {
  color: #000000;
}

.va-radio-button__inner .is-active {
  background-color: #000000;
}

.va-tabs__container .va-tabs__slider-wrapper {
  background-color: #ebd69c;
}

.title {
  text-align: left;
}

.total {
  font-size: 20px;
  padding: 10px;
  margin-right: 20px;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 1em;
}

::-webkit-scrollbar-thumb:hover {
  background: lightgrey;
}

.va-button.gray:hover::after {
  /* Add your hover message styles here */
  content: "mycreation";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: lightgray;
  color: black;
  font-size: 12px;
}

</style>
